import React, { useEffect, useState } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { getAppealByAdvertisementId } from "../../../store/admin/actions";
import { AppealCard } from "../../recruitment/components/Appeals";
import ReactPaginate from "react-paginate";
import TableSkeleton from "../../common/components/TableSkeleton";
import { setAppeal } from "../../../store/e-appeal/actions";
import ViewAppealDialog from "../../recruitment/components/ViewAppealDialog";
import SearchBox from "../../common/components/SearchBox";

const AdminAppealDialog = (props) => {
  const {
    user,
    loading,
    appeals,
    getAppealByAdvertisementId,
    closeAppealDialog,
    selectedAdvertisement,
    setAppeal,
  } = props;

  const [value, setValue] = useState(0);
  const [loadingAppeals, setLoadingAppeals] = useState(0);

  // FILTER
  const [searchTeam, setSearchTeam] = useState("");
  const [filteredAppeals, setFilteredAppeals] = useState([...appeals]);
  const [filterTypes, setFilterTypes] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  const [viewAppeal, setViewAppeal] = useState(false);

  //PAGINATION
  const [paginatedAppeals, setPaginatedAppeals] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  console.log({ selectedAdvertisement });

  useEffect(() => {
    if (selectedAdvertisement) {
      if (
        !appeals.find(
          (item) => item.advertisementId === selectedAdvertisement.id
        )
      ) {
        getAppealByAdvertisementId(selectedAdvertisement.id, setLoadingAppeals);
      }
    }
  }, [selectedAdvertisement]);

  useEffect(() => {
    const filtered = [...appeals].filter(
      ({ message, subject, appealTypeName, statusName }) =>
        (message && message.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (subject && subject.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (appealTypeName &&
          appealTypeName.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (statusName &&
          statusName.toLowerCase().includes(searchTeam.toLowerCase()))
    );

    setFilteredAppeals(filtered);
  }, [searchTeam, appeals]);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedAppeals(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredAppeals);
  }, [itemOffset, itemsPerPage, appeals, filteredAppeals]);

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredAppeals.length || 0;

    setItemOffset(newOffset);
  };

  const filterAppealTypes = () => {
    let tempFilteredAppealTypes = [];
    appeals.forEach((appeal) => {
      if (
        !tempFilteredAppealTypes.find(
          ({ name }) => name === appeal.appealTypeName
        )
      )
        tempFilteredAppealTypes.push({
          id: appeal.appealTypeId,
          name: appeal.appealTypeName,
        });
    });

    return tempFilteredAppealTypes;
  };
  //filterAppealStatus

  const filterAppealStatus = () => {
    let tempFilteredAppealStatus = [];
    appeals.forEach((appeal) => {
      if (
        !tempFilteredAppealStatus.find(({ name }) => name === appeal.statusName)
      )
        tempFilteredAppealStatus.push({
          id: appeal.statusId,
          name: appeal.statusName,
        });
    });

    return tempFilteredAppealStatus;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Dialog
        onClose={closeAppealDialog}
        aria-labelledby="customized-dialog-title"
        open={Boolean(selectedAdvertisement ? true : false)}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div">
            <span style={{ color: "rgb(7, 142, 206)" }} className="mr-1">
              Appeals
            </span>
            <span className="text-dark mr-1">@</span>
            <span className="text-success">
              {selectedAdvertisement && selectedAdvertisement.positionName}
            </span>
            {selectedAdvertisement &&
              selectedAdvertisement.adStatusId === 7 && (
                <span className="text-danger">(Advertisement Canceled)</span>
              )}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={closeAppealDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div className="row mb-2">
            <div className="col-lg-4">
              <Autocomplete
                size="small"
                id="filterStatus"
                disabled={!appeals.length}
                defaultValue={null}
                value={filterStatus}
                options={filterAppealStatus()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, filterStatus) => {
                  setFilterStatus(filterStatus || null);
                  setSearchTeam("");
                  if (filterStatus) setSearchTeam(filterStatus.name);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, filterStatus) => (
                  <Box component="li" {...props} key={filterStatus.id}>
                    {filterStatus.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    style={{
                      height: "35px!important",
                      position: "relative",
                      borderRadius: "8px",
                      padding: "1px!important",
                    }}
                    {...params}
                    label="Filter By Appeal Status"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "filterStatus", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </div>
            <div className="col-lg-4">
              <Autocomplete
                size="small"
                id="filterTypes"
                disabled={!appeals.length}
                defaultValue={null}
                value={filterTypes}
                options={filterAppealTypes()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, filterTypes) => {
                  setFilterTypes(filterTypes || null);
                  setSearchTeam("");
                  if (filterTypes) setSearchTeam(filterTypes.name);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, filterTypes) => (
                  <Box component="li" {...props} key={filterTypes.id}>
                    {filterTypes.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    style={{
                      height: "35px!important",
                      position: "relative",
                      borderRadius: "8px",
                      padding: "1px!important",
                    }}
                    {...params}
                    label="Filter By Appeal Type"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "filterTypes", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </div>
            <div className="col-lg-4">
              <SearchBox
                disabled={!appeals.length}
                onSearch={onSearch}
                placeholder="Search…"
              />
            </div>
          </div>

          <table className="table-striped table-hover table-sm  fixTableHead">
            <thead>
              <tr style={{ backgroundColor: "#f0f2f5" }}>
                <th scope="col" className="text-left">
                  #
                </th>

                <th scope="col" className="text-left">
                  Appealer
                </th>
                {/* <th scope="col" className="text-left">
                          Message
                        </th> */}
                <th scope="col" className="text-left">
                  Appeal Type
                </th>
                <th scope="col" className="text-right">
                  Appealed at
                </th>
                <th scope="col" className="text-right">
                  Level
                </th>
                <th scope="col" className="text-right">
                  Status
                </th>

                <th scope="col" className="text-right">
                  <span className="mr-3">Actions</span>
                </th>
              </tr>
            </thead>
            {loadingAppeals ? (
              <TableSkeleton cols={7} />
            ) : (
              <tbody>
                {filteredAppeals.length ? (
                  paginatedAppeals.map((appealItem, index) => (
                    <AppealCard
                      appeal={appealItem}
                      index={index}
                      setViewAppeal={setViewAppeal}
                      setSelectedAppeal={setAppeal}
                      key={index}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} align="center" className="py-3">
                      No results found
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>

          {/* appeals pagination controller ---------------- */}
          {!!filteredAppeals.length && appeals.length > itemsPerPage && (
            <div
              className="justify-content-center mt-2"
              style={{ overflow: "hidden" }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            </div>
          )}

          {/* view selected appeal ---------------- */}
          {viewAppeal && (
            <ViewAppealDialog
              entitySectorName={selectedAdvertisement.entityName}
              setViewAppeal={setViewAppeal}
              viewAppeal={viewAppeal}
              userType="ADMIN"
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading, recruitment }) => {
  return { user, loading, appeals: recruitment.appeals };
};
export default connect(mapStateToProps, {
  getAppealByAdvertisementId,
  setAppeal,
})(AdminAppealDialog);
