import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Autocomplete,
  Checkbox,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  saveWrittenExam,
  saveOralExam,
  getActiveCenters
} from "../../../store/recruitment/actions";
import { showError } from "../../../app/toastify";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ErrorMessage from "../../common/components/ErrorMessage";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { removeDuplicates } from "../../common/components/Utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddScheduleExamDialog = (props) => {
  const {
    loading,
    showAddScheduleExamForm,
    setShowScheduleExamForm,

    saveWrittenExam,
    saveOralExam,
    advertisement,
    examType,
    currentDate,
    isExamDateUnlocked,
    activeCenters,
    getActiveCenters
  } = props;

  // getActiveCenters

  useEffect(() => {
    if (examType === "Written" && !activeCenters.length) getActiveCenters();
  }, []);


  const [formData, setFormData] = useState({
    advertisementId: advertisement ? advertisement.id : 0,
    durationMin: examType === "Oral" ? 30 : 150,
    totalMarks:examType === "Oral" ? 50 : 100,
    seatingDate: null,
    startTime: null,
    hasPDF: false,
    endTime: "",
    venue: "",
    notice:"",
    examCenters:[]
  });

  const [errors, setErrors] = useState({
    durationMinHasError: false,
    totalMarksHasError: false,
    seatingDateHasError: false,
    startTimeHasError: false,
    endTimeHasError: false,
    venueHasError: false,
    hasError: false,
    noticeHasError:false,
    examCentersError:false
  });

  const formValidator = () => {
    const error = {
      durationMinHasError: false,
      totalMarksHasError: false,
      seatingDateHasError: false,
      startTimeHasError: false,
      endTimeHasError: false,
      hasError: false,
      venueHasError: false,
      noticeHasError:false
    };

    if (!formData.seatingDate) {
      error.seatingDateHasError = true;
      error.hasError = true;
    }

    if (!formData.durationMin) {
      error.durationMinHasError = true;
      error.hasError = true;
    }

    if (!formData.totalMarks) {
      error.totalMarksHasError = true;
      error.hasError = true;
    }
    if (!formData.notice) {
      error.noticeHasError = true;
      error.hasError = true;
    }

    if (!formData.startTime) {
      error.startTimeHasError = true;
      error.hasError = true;
    }

    if (examType === "Written" && !formData.endTime) {
      error.endTimeHasError = true;
      error.hasError = true;
    }
    if (!formData.venue && examType === "Oral") {
      error.venueHasError = true;
      error.hasError = true;
    }
    if (!formData.examCenters.length && examType === "Written") {
      error.venueHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      formData.advertisementId = advertisement ? advertisement.id : 0;

      formData.seatingDate = moment(formData.seatingDate).format("MM/DD/YYYY");
      formData.seatingDate = formData.seatingDate.toString();
      if (examType === "Written") {
        saveWrittenExam(formData, setFormData, setShowScheduleExamForm);
      }
      if (examType === "Oral") {
        saveOralExam(formData, setFormData, setShowScheduleExamForm);
      }
    }
  };

  const onClose = () => {
    setFormData({
      advertisementId: advertisement ? advertisement.id : 0,
      durationMin: 0,
      totalMarks:
        advertisement &&
        advertisement.willHaveOralExam &&
        advertisement.willHaveWrittenExam
          ? 50
          : 100,
      seatingDate: null,
      startTime: null,
      hasPDF: false,
      endTime: "",
      venue: "",
      notice:"",
      examCenters:[]
    });
    setShowScheduleExamForm(false);
  };
  const addDays = (currentDate,days) => {
    var date = currentDate?new Date(currentDate):new Date(currentDate);
    date.setDate(date.getDate() + days);
    return date;
  };


  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAddScheduleExamForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography noWrap variant="overline" display="block">
            <span className="text-primary">Schedule {examType} Exam</span> @{" "}
            {advertisement.positionName}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="m-2">
                  {examType === "Written"?
                   <>
                   <Autocomplete
                     size="small"
                     fullWidth
                     multiple
                     id="examCenters"
                     options={activeCenters}
                     value={formData.examCenters}
                     onChange={(e, examCenters) => {
                      const uniqueExamCenters = removeDuplicates(examCenters);
                      setFormData({ ...formData, examCenters: uniqueExamCenters });

                       const examCentersError = false;
                       setErrors({ ...errors, examCentersError });
                     }}
                     disableCloseOnSelect
                     getOptionLabel={(option) => option.name}
                     renderOption={(props, option, { selected }) => (
                       <li {...props}>
                         <Checkbox
                           icon={icon}
                           checkedIcon={checkedIcon}
                           checked={selected}
                         />
                         {option.name}
                       </li>
                     )}
                     renderInput={(params) => (
                       <TextField
                         {...params}
                         label={"Choose the exam centers"}
                         placeholder={
                           "Choose the exam centers"
                         }
                       />
                     )}
                   />

                   {errors.examCentersError && (
                     <small className="text-danger mb-1">
                       Please, select the exam centers
                     </small>
                   )}
                 </>:<>
                 <TextField
                    fullWidth
                    size="small"
                    rows={4}
                    name="venue"
                    autoFocus
                    label="Venue"
                    variant="outlined"
                    placeholder="Venue"
                    value={formData.venue}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const venue = e.target.value;
                      setFormData({ ...formData, venue });
                      const venueHasError = false;
                      setErrors({ ...errors, venueHasError });
                    }}
                  />

                  <ErrorMessage
                    hasError={errors.venueHasError}
                    message="Venue is required"
                  />
                 </>}
               

                 
                </div>
              </div>
              <div className="col-lg-12">
                <div className="m-2">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Seating Date"
                      inputFormat="MM/dd/yyyy"
                      value={formData.seatingDate}
                      minDate={!isExamDateUnlocked ? addDays(currentDate, 3) : null}
                      onChange={(date) => {
                        const seatingDate = date;
                        let seatingDateHasError = false;
                        if (new Date(seatingDate).getDate() <
                          new Date(addDays(currentDate,3)).getDate()
                        ) {
                          console.log('herrrrrr');
                          seatingDateHasError = true;
                        }
                        setFormData({ ...formData, seatingDate });

                        setErrors({ ...errors, seatingDateHasError });
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <ErrorMessage
                    hasError={errors.seatingDateHasError}
                    message="Seating date is required"
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="m-2">
                  <TextField
                    fullWidth
                    size="small"
                    label="Start Time"
                    type="time"
                    disabled={
                      !formData.seatingDate ||
                      !formData.durationMin ||
                      errors.seatingDateHasError
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.startTime || ""}
                    onChange={(e) => {
                      const startTime = e.target.value;

                      const seatingDate = moment(formData.seatingDate).format(
                        "YYYY-MM-DD"
                      );
                      const seatingDateTime = seatingDate + " " + startTime;
                      const endTime = moment(seatingDateTime)
                        .add(formData.durationMin, "m")
                        .format("HH:mm");

                      setFormData({
                        ...formData,
                        startTime,
                        endTime,
                      });
                      setErrors({
                        ...errors,
                        startTimeHasError: false,
                        endTimeHasError: false,
                      });
                    }}
                  />

                  <ErrorMessage
                    hasError={errors.startTimeHasError}
                    message="Start time is required"
                  />
                </div>
              </div>
                  {examType === "Written" &&    
                    <div className="col-lg-12">
                <div className="m-2">
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    label="End Time"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.endTime}
                    onChange={(e) => {
                      setErrors({ ...errors, startTimeHasError: false });
                    }}
                  />

                  <ErrorMessage
                    hasError={errors.endTimeHasError}
                    message="End time is required"
                  />
                </div>
              </div>}

              <div className="col-lg-12">
                <div className="m-2">
                <TextField
                fullWidth
                multiline
                rows={4}
                size="small"
                name="notice"
                label={`Notification message`}
                variant="outlined"
                placeholder={`Notice`}
                value={formData.notice}
                
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const notice = e.target.value;
                  setFormData({
                    ...formData,
                    notice,
                  });

                  setErrors({
                    ...errors,
                    noticeHasError: false,
                  });
                }}
              />

                  <ErrorMessage
                    hasError={errors.noticeHasError}
                    message="Notice is required"
                  />
                </div>
              </div>
           
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user,activeCenters }) => {
  return {
    loading,
    user,
    activeCenters
  };
};
export default connect(mapStateToProps, {
  saveWrittenExam,
  saveOralExam,
  getActiveCenters
})(AddScheduleExamDialog);
