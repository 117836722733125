import {
  IconButton,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Button,
  Skeleton,
  InputAdornment,
  Menu,
  MenuItem,
  Badge,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import {
  saveEmployeeWithhold,
  getEmployeeWithholds,
  deleteEmployeeWithHold,
  approveEmployeeWithhold,
} from "../../../store/structure/actions";

import { useTheme } from "@mui/material/styles";
import { showError } from "../../toastify";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import NumberFormat from "react-number-format";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import RejectEmployeeDeductionDialog from "./RejectEmployeeDeductionDialog";
import RejectionReasonDialog from "./RejectionReasonDialog";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const _PaperProps = {
  elevation: 0,
  sx: {
    overflow: "auto",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 2,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 15,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};
const EmployeeDeductions = (props) => {
  const {
    loading,
    onWait,
    selectedEmployee,
    saveEmployeeWithhold,
    deleteEmployeeWithHold,
    employeeWithholds,
    creditors,
    calculationMethods,
    basePayrollComponents,
    approveEmployeeWithhold,
    payrollTypes,
    disabled = false,
  } = props;

  const [creditor, setCreditor] = useState(null);
  const [payrollType, setPayrollType] = useState(null);
  const [calculationMethod, setCalculationMethod] = useState(null);
  const [basePayrollComponent, setBasePayrollComponent] = useState(null);
  const [selectedEmployeeWithhold, setSelectedEmployeeWithhold] =
    useState(null);
  const [confirmRemoveEmployeeWithhold, setConfirmRemoveEmployeeWithhold] =
    useState(false);
  const [showApproveEmployeeWithhold, setApproveEmployeeWithhold] =
    useState(false);
  const theme = useTheme();
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    employeeId: selectedEmployee.id,
    employeePositionId: selectedEmployee.employeePositionId,
    creditorId: "",
    amount: 0,
    totalAmount: 0,
    rate: 0,
    endDate: null,
    payrollTypeId: 1,
  });

  const [add, setAdd] = useState(false);
  const [isRate, setIsRate] = useState(false);

  const [errors, setErrors] = useState({
    creditorHasError: false,
    amountHasError: false,
    endDateHasError: false,
    rateAmountHasError: false,
    payrollTypeHasError: false,
  });

  useEffect(() => {
    const add = employeeWithholds?.length > 0 ? false : true;

    setAdd(add);
  }, [employeeWithholds]);

  const formValidator = () => {
    const error = {
      creditorHasError: false,
      hasError: false,
      amountHasError: false,
      rateHasError: false,
      endDateHasError: false,
      totalAmountHasError: false,
      payrollTypeHasError: false,
    };

    if (!formData.creditorId) {
      error.creditorHasError = true;
      error.hasError = true;
    }
    if (!formData.endDate && creditor?.id !== 9) {
      error.endDateHasError = true;
      error.hasError = true;
    }

    if (!formData.amount && !isRate) {
      error.amountHasError = true;
      error.hasError = true;
    }

    if (!formData.rate && isRate) {
      error.rateHasError = true;
      error.hasError = true;
    }

    if (!formData.totalAmount && !disableTotalAmount) {
      error.totalAmountHasError = true;
      error.hasError = true;
    }

    if (!payrollType) {
      error.payrollTypeHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!!formData.creditorId)
      setCreditor(
        creditors.find(({ id }) => id === formData.creditorId) || null
      );
    else setCreditor(null);
  }, [formData.creditorId]);

  useEffect(() => {
    if (!!formData.payrollTypeId)
      setPayrollType(
        payrollTypes.find(({ id }) => id === formData.payrollTypeId) || null
      );
    else setPayrollType(null);
  }, [formData.payrollTypeId]);

  useEffect(() => {
    if (!!creditor) {
      setCalculationMethod(
        calculationMethods.find(({ id }) => id === creditor.calculationMethodId)
      );
      setBasePayrollComponent(
        basePayrollComponents.find(
          ({ id }) => id === creditor.basePayrollComponentId
        )
      );

      if (!formData.id)
        setFormData({
          ...formData,
          amount: 0,
          rate: creditor.rate,
          endDate: formData.endDate || null,
          payrollTypeId: 1,
        });
    } else {
      setCalculationMethod(null);
      setBasePayrollComponent(null);
      setIsRate(false);

      if (!formData.id)
        setFormData({
          ...formData,
          rate: 0,
          amount: 0,
          endDate: null,
          payrollTypeId: 1,
        });
    }
  }, [creditor]);

  useEffect(() => {
    if (!!calculationMethod) {
      setIsRate(calculationMethod.id === 1 ? false : true);
    }
  }, [calculationMethod]);

  const [disableTotalAmount, setDisableTotalAmount] = useState(true);

  const onSave = () => {
    if (!formValidator()) {
      if (
        !disableTotalAmount &&
        parseInt(formData.amount) > parseInt(formData.totalAmount)
      ) {
        showError(
          "Total amount to be deducted must be greater than monthly deduction"
        );
        return false;
      }

      // let canEditCreditor = false;
      // if (parseInt(formData.id) > 0 && add) {
      //   canEditCreditor = true;
      // }

      // console.log(formData);
      saveEmployeeWithhold(
        formData,
        setFormData,
        setAdd,
        setCreditor,
        setDisableTotalAmount
        // canEditCreditor
      );
    }
  };

  const getNo = (idToSearch, data) => {
    return data.findIndex(({ id }) => id === idToSearch) + 1;
  };

  const [file, setFile] = useState(null);
  const [reason, setReason] = useState("");

  useEffect(() => {
    setReason("");
    setFile(null);
  }, [confirmRemoveEmployeeWithhold]);

  const onDeleteEmployeeWithHold = () => {
    const formData = new FormData();
    formData.append("id", selectedEmployeeWithhold?.id);
    formData.append("reason", reason || "");
    formData.append("file", file);

    deleteEmployeeWithHold(
      selectedEmployeeWithhold?.id,
      formData,
      setConfirmRemoveEmployeeWithhold,
      setSelectedEmployeeWithhold
    );
  };

  return (
    <div style={{ minHeight: "50vh" }}>
      {showApproveEmployeeWithhold && (
        <RejectEmployeeDeductionDialog
          approveEmployeeWithhold={showApproveEmployeeWithhold}
          setApproveEmployeeWithhold={setApproveEmployeeWithhold}
          selectedEmployeeWithhold={selectedEmployeeWithhold}
        />
      )}

      {loading && !onWait && (
        <div className="container mt-5" style={{ width: "100%" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </div>
      )}

      {(!loading || onWait) && (
        <div>
          {!add && (
            <>
              <Button
                disabled={disabled}
                className="mb-2"
                onClick={() => {
                  setAdd(true);
                }}
                style={{
                  ...ActiveButton,
                  padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                  float: "right",
                }}
                size="sm"
              >
                <span className="material-icons">add</span>
              </Button>
            </>
          )}
          {add && (
            <div
              className="elevated rounded p-0 mt-3 p-3"
              style={{
                backgroundColor: "#fafafa",
                border: "1px solid #17a2b8",
              }}
            >
              <div className="row mt-3 ">
                <div className="mb-3 col-12 col-lg-6">
                  <div className="">
                    <Autocomplete
                      readOnly={!!formData.id}
                      size="small"
                      id="creditor"
                      defaultValue={null}
                      value={creditor}
                      options={creditors}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, creditor) => {
                        setErrors({ ...errors, creditorHasError: false });

                        setFormData({
                          ...formData,
                          creditorId: creditor?.id || "",
                          id: 0,
                        });
                      }}
                      getOptionLabel={(option) =>
                        getNo(option.id, creditors) +
                        "-" +
                        option.name +
                        (option.calculationMethodId === 2
                          ? " (" + option.calculationMethodName + ")"
                          : "")
                      }
                      renderOption={(props, creditor) => (
                        <Box component="li" {...props}>
                          {getNo(creditor.id, creditors)} - {creditor.name}
                          {creditor.calculationMethodId === 2
                            ? " (" + creditor.calculationMethodName + ")"
                            : ""}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select creditors"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "creditor", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />

                    {errors.creditorHasError && (
                      <small className="text-danger mb-3">
                        Please, select creditor
                      </small>
                    )}
                  </div>
                </div>

                <div
                  className={`mb-3 col-12 ${!isRate ? "col-lg-6" : "col-lg-3"}`}
                >
                  <div className="">
                    <Autocomplete
                      style={{ backgroundColor: "#eee" }}
                      size="small"
                      disabled
                      id="Method"
                      defaultValue={null}
                      value={calculationMethod}
                      options={calculationMethods}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, calculationMethod) => {
                        setCalculationMethod(calculationMethod || null);
                        const calculationMethodId = calculationMethod
                          ? calculationMethod.id
                          : "";
                        setFormData({ ...formData, calculationMethodId });
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, calculationMethod) => (
                        <Box component="li" {...props}>
                          {calculationMethod.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Method"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                {/* <div className="col-12 col-lg-3">
                      <div className="">
                        <TextField
                          fullWidth
                          size="small"
                          rows={4}
                          name="totalAmount"
                          autoFocus
                          label="Total Deduction Amount"
                          variant="outlined"
                          placeholder="Total Deduction Amount"
                          className="mb-3"
                          value={formData.totalAmount}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            const totalAmount = e.target.value;
                            setFormData({ ...formData, totalAmount });
                          }}
                        />
                        {errors.totalAmountHasError && (
                          <div className="text-danger mb-2">
                            Total Deduction Amount is required{" "}
                          </div>
                        )}
                      </div>
                    </div> */}

                {isRate && (
                  <div className="mb-3 col-12 col-lg-3">
                    <div className="">
                      <Autocomplete
                        style={{ backgroundColor: "#eee" }}
                        size="small"
                        disabled
                        id="baseComponent"
                        defaultValue={null}
                        value={basePayrollComponent}
                        options={basePayrollComponents}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, basePayrollComponent) => {
                          setBasePayrollComponent(basePayrollComponent || null);
                          const basePayrollComponentId = basePayrollComponent
                            ? basePayrollComponent.id
                            : "";
                          setFormData({
                            ...formData,
                            basePayrollComponentId,
                          });
                        }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, basePayrollComponent) => (
                          <Box component="li" {...props}>
                            {basePayrollComponent.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Base Component"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "baseComponent", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />

                      {/* {errors.basePayrollComponent && (
                          <small className="text-danger mb-3">
                            Base component is required
                          </small>
                        )} */}
                    </div>
                  </div>
                )}

                <div className="mb-3 col-12 col-lg-6">
                  <TextField
                    style={{ backgroundColor: "#eee" }}
                    disabled
                    size="small"
                    fullWidth
                    name="acountNumber"
                    type="text"
                    label="Acount Number"
                    variant="outlined"
                    placeholder="Acount Number"
                    value={!!creditor ? creditor.acountNumber : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="mb-3 col-12 col-lg-6">
                  <TextField
                    style={{ backgroundColor: "#eee" }}
                    disabled
                    size="small"
                    fullWidth
                    name="bank"
                    type="text"
                    label="Bank"
                    variant="outlined"
                    placeholder="Bank"
                    value={!!creditor ? creditor.bankName : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="mb-3 col-12 col-lg-3">
                  {!isRate && (
                    <div className="">
                      <NumberFormat
                        fullWidth
                        size="small"
                        label="Monthly deduction"
                        name="amount"
                        variant="outlined"
                        placeholder="RWF 0"
                        prefix={"RWF "}
                        customInput={TextField}
                        value={formData.amount}
                        thousandSeparator={true}
                        onBlur={() => {
                          if (formData.amount === "")
                            setFormData({
                              ...formData,
                              amount: 0,
                            });
                        }}
                        onKeyDown={() => {
                          if (+formData.amount === 0)
                            setFormData({
                              ...formData,
                              amount: "",
                            });
                        }}
                        onChange={(e) => {
                          setErrors({ ...errors, amountHasError: false });
                          const amount = e.target.value.split("RWF ");

                          setFormData({
                            ...formData,
                            amount: !!amount[1]
                              ? amount[1].replace(/,/g, "")
                              : 0,
                          });
                        }}
                      />
                      {errors.amountHasError && (
                        <small className="text-danger mb-2">
                          Amount is required{" "}
                        </small>
                      )}
                    </div>
                  )}

                  {isRate && (
                    <div className="">
                      <TextField
                        style={{ backgroundColor: "#eee" }}
                        size="small"
                        disabled
                        fullWidth
                        name="rate"
                        label="Rate"
                        variant="outlined"
                        placeholder="Rate"
                        value={formData.rate}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setErrors({ ...errors, rateHasError: false });
                          const rate = e.target.value;
                          setFormData({ ...formData, rate });
                        }}
                      />
                      {errors.rateHasError && (
                        <small className="text-danger mb-2">
                          Rate is required{" "}
                        </small>
                      )}
                    </div>
                  )}
                </div>

                <div className="mb-3 col-12 col-lg-3">
                  <div className="row no-gutters">
                    <div className="col-10">
                      {disableTotalAmount && (
                        <TextField
                          style={{ backgroundColor: "#eee" }}
                          size="small"
                          disabled
                          fullWidth
                          name="totalAmount"
                          label="Total to be paid"
                          variant="outlined"
                          placeholder="Rate"
                          value="N/A"
                        />
                      )}
                      {!disableTotalAmount && (
                        <NumberFormat
                          fullWidth
                          size="small"
                          label="Total to be paid"
                          name="totalAmount"
                          variant="outlined"
                          autoFocus={true}
                          placeholder="RWF 0"
                          prefix={"RWF "}
                          customInput={TextField}
                          value={formData.totalAmount}
                          thousandSeparator={true}
                          onBlur={() => {
                            if (formData.totalAmount === "")
                              setFormData({
                                ...formData,
                                totalAmount: 0,
                              });
                          }}
                          onKeyDown={() => {
                            if (+formData.totalAmount === 0)
                              setFormData({
                                ...formData,
                                totalAmount: "",
                              });
                          }}
                          onChange={(e) => {
                            setErrors({
                              ...errors,
                              totalAmountHasError: false,
                            });
                            const totalAmount = e.target.value.split("RWF ");

                            setFormData({
                              ...formData,
                              totalAmount: !!totalAmount[1]
                                ? totalAmount[1].replace(/,/g, "")
                                : 0,
                            });
                          }}
                        />
                      )}

                      {errors.totalAmountHasError && (
                        <div className="text-danger mb-2">
                          Total to be paid is required{" "}
                        </div>
                      )}
                    </div>
                    <div className="col-2">
                      <button
                        disabled={disabled}
                        onClick={() => {
                          setDisableTotalAmount(!disableTotalAmount);
                          setErrors({
                            ...errors,
                            totalAmountHasError: false,
                          });
                          setFormData({
                            ...formData,
                            totalAmount: 0,
                          });
                        }}
                        type="button"
                        className="btn btn-outline-secondary ml-1 btn-sm text-uppercase"
                      >
                        {!disableTotalAmount && (
                          <span className="material-icons">edit_off</span>
                        )}

                        {disableTotalAmount && (
                          <span className="material-icons">
                            mode_edit_outline
                          </span>
                        )}
                      </button>{" "}
                    </div>
                  </div>
                </div>

                {creditor?.id !== 9 && (
                  <div className="mb-3 col-12 col-lg-3">
                    <div className="">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          // readOnly={!!formData.id}
                          label="End Date"
                          inputFormat="MM/dd/yyyy"
                          value={formData.endDate || null}
                          onChange={(date) => {
                            setErrors({
                              ...errors,
                              endDateHasError: false,
                            });
                            const endDate = date;
                            setFormData({ ...formData, endDate });

                            const endDateHasError = false;
                            setErrors({ ...errors, endDateHasError });
                          }}
                          renderInput={(params) => (
                            <TextField size="small" fullWidth {...params} />
                          )}
                        />

                        {errors.endDateHasError && (
                          <small className="text-danger mt-1">
                            End Date is required{" "}
                          </small>
                        )}
                      </LocalizationProvider>
                    </div>
                  </div>
                )}

                <div
                  className={`mb-3 col-12 ${
                    creditor?.id !== 9 ? "col-lg-3" : "col-lg-6"
                  } `}
                >
                  <Autocomplete
                    size="small"
                    id="payrollType"
                    defaultValue={null}
                    value={payrollType}
                    options={payrollTypes.filter(({ id }) => id !== 2)}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, payrollType) => {
                      setErrors({ ...errors, payrollTypeHasError: false });

                      setFormData({
                        ...formData,
                        payrollTypeId: payrollType?.id || 1,
                      });

                      setPayrollType(payrollType);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, payrollType) => (
                      <Box component="li" {...props}>
                        {payrollType.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payroll Type"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "PayrollType", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />

                  {errors.payrollTypeHasError && (
                    <small className="text-danger mb-3">
                      Payroll type is required
                    </small>
                  )}
                </div>
              </div>

              <Divider className="my-1" />
              <div className="d-flex justify-content-center py-0 p-2">
                <button
                  onClick={onSave}
                  type="button"
                  className="btn btn-primary text-uppercase"
                  disabled={disabled || loading || onWait}
                >
                  {onWait ? "Wait..." : "Save"}
                </button>{" "}
                {!!employeeWithholds?.length && (
                  <React.Fragment>
                    &nbsp; &nbsp;
                    <button
                      onClick={() => {
                        setAdd(false);
                        setCreditor(null);

                        setFormData({
                          employeeId: selectedEmployee.id,
                          employeePositionId:
                            selectedEmployee.employeePositionId,
                          creditorId: "",
                          amount: 0,
                          rate: 0,
                          totalAmount: 0,
                          endDate: null,
                          id: null,
                        });

                        setDisableTotalAmount(true);
                      }}
                      type="button"
                      className="btn  btn-default text-uppercase"
                      disabled={disabled || loading}
                    >
                      Cancel
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}

          {!!employeeWithholds?.length && (
            <React.Fragment>
              <table className="table table-bordered table-sm mt-3">
                <thead>
                  <tr>
                    <th colSpan={10} style={{ textAlign: "center" }}>
                      ACTIVE DEDUCTIONS
                    </th>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#f6f6f6" }}>#</td>
                    <td style={{ backgroundColor: "#f6f6f6" }}>Creditor</td>

                    <td style={{ backgroundColor: "#f6f6f6" }}>PayrollType</td>

                    {/* <td
                      style={{ backgroundColor: "#f6f6f6" }}
                      className="text-right"
                    >
                      EndDate
                    </td> */}

                    <td
                      colSpan={2}
                      style={{ backgroundColor: "#f6f6f6" }}
                      className="text-center"
                    >
                      MonthlyDeduction
                    </td>
                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-right"
                    >
                      TotalToPay
                    </td>
                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-right"
                    >
                      TotalPaid
                    </td>
                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-right"
                    >
                      Outstanding
                    </td>
                    <td
                      style={{ backgroundColor: "#f6f6f6" }}
                      className="text-right"
                    >
                      EndDate
                    </td>
                    {/* <td style={{ backgroundColor: "#f6f6f6" }}>End Date</td> */}
                    <td style={{ backgroundColor: "#f6f6f6" }}></td>
                  </tr>
                </thead>
                <tbody>
                  {employeeWithholds.map((unitWithhold, uaIndex) => (
                    <EmployeeDeductionItem
                      key={uaIndex}
                      unitWithhold={unitWithhold}
                      setCreditor={setCreditor}
                      setFormData={setFormData}
                      loading={loading}
                      setConfirmRemoveEmployeeWithhold={
                        setConfirmRemoveEmployeeWithhold
                      }
                      approveEmployeeWithhold={approveEmployeeWithhold}
                      setShowRejectionReason={setShowRejectionReason}
                      setApproveEmployeeWithhold={setApproveEmployeeWithhold}
                      setAdd={setAdd}
                      setSelectedEmployeeWithhold={setSelectedEmployeeWithhold}
                      uaIndex={uaIndex}
                      setDisableTotalAmount={setDisableTotalAmount}
                      disabled={disabled}
                    />
                  ))}
                </tbody>
              </table>
              {showRejectionReason && (
                <RejectionReasonDialog
                  reason={selectedEmployeeWithhold.rejectReason}
                  showRejectionReason={showRejectionReason}
                  setShowRejectionReason={setShowRejectionReason}
                />
              )}
            </React.Fragment>
          )}
        </div>
      )}

      {confirmRemoveEmployeeWithhold && (
        <ConfirmationDialog
          disabled={
            !reason || (!!selectedEmployeeWithhold?.canNotEdit && !file)
          }
          btnMessage="Stop commitment"
          confirmationDialog={confirmRemoveEmployeeWithhold}
          message={
            <>
              <p>Are you sure you want to stop this commitment?</p>

              <TextField
                size="small"
                fullWidth
                multiline
                autoFocus
                rows={3}
                name="reason"
                label="Reason for stopping the commitment"
                variant="outlined"
                placeholder="Reason for stopping the commitment"
                value={reason}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />

              {!!selectedEmployeeWithhold?.canNotEdit && (
                <>
                  <div className={`text-left`}>
                    <small>Upload supporting document(pdf)</small>
                    <Button
                      fullWidth
                      className="d-flex justify-content-start"
                      component="label"
                      style={{
                        borderRadius: "0px",
                        height: "35px",
                        border: `1px solid #6c757d`,
                        color: "#fff",
                        alignItems: "center",
                        fontSize: ".75rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "initial",
                        backgroundColor: "#6c757d",
                        "&:hover": {
                          backgroundColor: "#6c757d",
                        },
                      }}
                      size="md"
                    >
                      <input
                        accept="application/pdf"
                        type="file"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setFile(file || null);
                        }}
                      />
                    </Button>
                  </div>
                </>
              )}
            </>
          }
          setConfirmationDialog={setConfirmRemoveEmployeeWithhold}
          onYes={onDeleteEmployeeWithHold}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  employeeWithholds,
  creditors,
  calculationMethods,
  basePayrollComponents,
  payrollTypes,
}) => {
  return {
    loading,
    onWait,
    employeeWithholds,
    creditors,
    calculationMethods,
    basePayrollComponents,
    payrollTypes,
  };
};
export default connect(mapStateToProps, {
  saveEmployeeWithhold,
  getEmployeeWithholds,
  deleteEmployeeWithHold,
  approveEmployeeWithhold,
})(EmployeeDeductions);

const EmployeeDeductionItem = (props) => {
  const {
    unitWithhold,
    setConfirmRemoveEmployeeWithhold,
    setSelectedEmployeeWithhold,
    setApproveEmployeeWithhold,
    setShowRejectionReason,
    approveEmployeeWithhold,
    uaIndex,
    setAdd,
    setFormData,
    setDisableTotalAmount,
    disabled,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <tr
      style={{
        background: uaIndex % 2 === 0 ? "" : "#f6f7f7",
        borderBottom: "2px dotted rgb(7, 142, 206)!important",
      }}
    >
      <td>{uaIndex + 1}</td>
      <td>
        {unitWithhold.creditorName} <br />{" "}
        <small>
          {unitWithhold.acountNumber} ({unitWithhold.bankName})
        </small>{" "}
      </td>

      <td className="text-left">{unitWithhold.payrollTypeName}</td>

      <td className="text-right" style={{ borderRight: "none" }}>
        {unitWithhold.calculationMethodId === 1 ? (
          <CurrencyFormat
            value={unitWithhold.amount}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          unitWithhold.rate
        )}
        <span
          className={`badge ml-1 badge-${
            unitWithhold.calculationMethodId === 1
              ? "light text-info"
              : "secondary"
          }`}
        >
          {" "}
          {unitWithhold.calculationMethodId === 1 ? "RWF" : "RATE"}
        </span>
      </td>

      <td className="text-left" style={{ borderLeft: "none" }}>
        <span
          className="badge badge-light text-uppercase text-info"
          style={{ fontSize: "12px" }}
        >
          {"/ " + unitWithhold.basePayrollComponentName}
        </span>
        {/* / {unitWithhold.basePayrollComponentName.replace(/ /g, "")}{" "} */}
      </td>
      <td style={{ backgroundColor: "#e9ecef" }} className="text-right">
        {!!unitWithhold.totalAmount && (
          <>
            <CurrencyFormat
              value={unitWithhold.totalAmount}
              displayType={"text"}
              thousandSeparator={true}
            />
            <span className={`badge text-info`}>RWF</span>
          </>
        )}

        {!unitWithhold.totalAmount && (
          <span className={`badge text-info`}>-</span>
        )}
      </td>
      <td style={{ backgroundColor: "#e9ecef" }} className="text-right">
        {!!unitWithhold.balance && (
          <>
            <CurrencyFormat
              value={unitWithhold.balance}
              displayType={"text"}
              thousandSeparator={true}
            />
            <span className={`badge text-info`}>RWF</span>
          </>
        )}
        {!unitWithhold.balance && <span className={`badge text-info`}>-</span>}
      </td>
      <td style={{ backgroundColor: "#e9ecef" }} className="text-right">
        {!!unitWithhold.totalAmount && (
          <>
            <CurrencyFormat
              value={+unitWithhold.totalAmount - +unitWithhold.balance}
              displayType={"text"}
              thousandSeparator={true}
            />
            <span className={`badge text-info`}>RWF</span>
          </>
        )}
        {!unitWithhold.totalAmount && (
          <span className={`badge text-info`}>-</span>
        )}
      </td>
      <td className="text-right">
        {unitWithhold.endDate
          ? moment(unitWithhold.endDate).format("yyyy-MM-DD")
          : ""}{" "}
      </td>

      <td className="text-right">
        {/* {!unitWithhold.canNotEdit && ( */}
        <span>
          <IconButton
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons text-primary">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            {!unitWithhold.canNotEdit && (
              <MenuItem
                disabled={disabled}
                onClick={() => {
                  handleCloseMenu();

                  setFormData({
                    id: unitWithhold.id,
                    employeeId: unitWithhold.employeeId,
                    employeePositionId: unitWithhold.employeePositionId,
                    creditorId: unitWithhold.creditorId,
                    amount: unitWithhold.amount,
                    totalAmount: unitWithhold.totalAmount,
                    rate: unitWithhold.rate,
                    endDate: unitWithhold.endDate
                      ? new Date(unitWithhold.endDate)
                      : null,
                    payrollTypeId: unitWithhold.payrollTypeId,
                  });

                  if (!!unitWithhold.totalAmount) setDisableTotalAmount(false);
                  setAdd(true);
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">edit</span>
                Edit
              </MenuItem>
            )}

            <span>
              <Divider className="my-1" />
              <MenuItem
                disabled={disabled}
                onClick={() => {
                  handleCloseMenu();
                  setSelectedEmployeeWithhold(unitWithhold);
                  setConfirmRemoveEmployeeWithhold(true);
                }}
                className="text-danger font-weight-bolder"
              >
                <span className="material-icons mr-1">highlight_off</span> Stop
              </MenuItem>
            </span>
          </Menu>
        </span>
        {/* )} */}

        {/* {!!unitWithhold.canNotEdit && (
          <span className="d-block text-center pl-3">-</span>
        )} */}
      </td>
    </tr>
  );
};
