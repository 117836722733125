import React, { useState, useEffect } from "react";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  Divider,
  DialogActions,
  Tooltip,
  Link,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import BadgeIcon from "@mui/icons-material/Badge";
import {
  CardContent,
  Button,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Badge,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  getApplicantOralExamResults,
  downloadOralExamResults,
  publishOralTopupScore,
} from "../../../store/recruitment/actions";
import { getUserDetails } from "../../../store/profile/actions";
import QuizIcon from "@mui/icons-material/Quiz";
import TopupScoreDialog from "./TopupScoreDialog";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
import { connect, useDispatch } from "react-redux";
import ApplicantOralExamAnswerDialog from "./ApplicantOralExamAnswerDialog";
import ApplicationCv from "../../common/components/ApplicationCv";
import UserResume from "../../profile/UserResume";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
const OralExamMarking = (props) => {
  const {
    loading,
    exam,
    setShowOralExamMarking,
    showOralExamMarking,
    getApplicantOralExamResults,
    applicantOralExamResults,
    downloadOralExamResults,
    getUserDetails,
    publishOralTopupScore,
  } = props;
  const theme = useTheme();
  const [searchTeam, setSearchTeam] = useState("");
  const [filterStatus, setFilterStatus] = useState(null);
  const [showTopupScore, setShowTopupScore] = useState(false);
  const { hasPermissions } = useRequiredAuth();
  const [showApplicantOralExamAnswers, setShowApplicantOralExamAnswers] =
    useState(false);
  const [selectedApplicantMarks, setSelectedApplicantMarks] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredApplicants, setFilteredApplicants] = useState([
    ...applicantOralExamResults,
  ]);
  const [isDownloading, setIsDownloading] = useState(false);
  useEffect(() => {
    getApplicantOralExamResults(exam.id, setIsLoading);
  }, [exam]);

  const onClose = () => {
    setShowOralExamMarking(false);
  };

  const filterResultsStatuses = () => {
    let tempFilteredStatus = [];
    applicantOralExamResults.forEach((result) => {
      if (!tempFilteredStatus.find(({ name }) => name === result.status))
        tempFilteredStatus.push({
          id:
            result.statusId === 1
              ? -1
              : result.statusId === 0
              ? -2
              : result.statusId,
          name: result.status,
        });
    });

    return tempFilteredStatus;
  };

  useEffect(() => {
    doFilterByStatusName();
  }, [filterStatus, applicantOralExamResults]);

  const doFilterByStatusName = () => {
    const filterStatusName = filterStatus
      ? filterStatus.name.toString().toLowerCase()
      : null;

    let filtered = [...applicantOralExamResults].filter(({ status }) =>
      status.toString().toLowerCase().includes(filterStatusName)
    );
    if (filterStatusName === null) filtered = [...applicantOralExamResults];

    setFilteredApplicants(filtered);
    paginate(filtered);
  };

  useEffect(() => {
    const filtered = [...applicantOralExamResults].filter(
      ({ lastName, firstName, phoneNumber }) =>
        lastName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        firstName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        phoneNumber.toLowerCase().includes(searchTeam.toLowerCase())
      // unitName.toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilteredApplicants(filtered);
    paginate(filtered);
  }, [searchTeam, applicantOralExamResults]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(() => {
    paginate(filteredApplicants);
  }, [itemOffset, itemsPerPage, applicantOralExamResults, filteredApplicants]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredApplicants.length;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showOralExamMarking}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark  mr-2">Oral Exam</span>

            <span style={{ color: "rgb(7, 142, 206)" }}>results</span>

            <span className="ml-2">
              @
              {exam.advertisement
                ? exam.advertisement.requisition.position.name
                : ""}
            </span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box sx={{ display: "flex", width: "100%" }}>
            {/*  */}

            <div
              className="w-100"
              style={{
                height: "520px",
              }}
            >
              <div
                style={{
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  overflow: "visible",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <CardContent
                  style={{
                    // padding: ".6em",
                    height: "auto",
                    minHeight: "auto",
                    overflow: "hidden",
                  }}
                >
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <Typography variant="h6" noWrap component="div">
                        <span style={{ color: "#2a7790" }}>
                          <QuizIcon className="text-dark mr-2" />
                        </span>
                        <span style={{ color: "rgb(7, 142, 206)" }}>
                          Candidates oral results ({filteredApplicants.length})
                        </span>
                      </Typography>
                    </div>

                    <div className="col-lg-6 mb-2">
                      <div className="d-flex justify-content-end">
                        <div className="ml-2">
                          <SearchBox
                            disabled={!applicantOralExamResults.length}
                            onSearch={onSearch}
                            placeholder="Search…"
                          />
                        </div>
                        <div className="ml-2">
                          <Autocomplete
                            size="small"
                            id="filterStatus"
                            disabled={!applicantOralExamResults.length}
                            defaultValue={null}
                            value={filterStatus}
                            options={filterResultsStatuses()}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, filterStatus) => {
                              setFilterStatus(filterStatus || null);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, filterStatus) => (
                              <Box
                                component="li"
                                {...props}
                                key={filterStatus.id}
                              >
                                {filterStatus.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                style={{
                                  height: "35px!important",
                                  width: "200px",
                                  position: "relative",
                                  borderRadius: "8px",
                                  padding: "1px!important",
                                }}
                                {...params}
                                label="Filter By"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "filterStatus", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </div>

                        <span className="ml-0 btn btn-outline-primary btn-sm">
                          <Tooltip title="Download">
                            <IconButton
                              disabled={isDownloading}
                              className="p-0"
                              color="primary"
                              onClick={() => {
                                const query = {
                                  examId: exam.id,
                                  examName: exam.advertisement
                                    ? exam.advertisement.requisition.position
                                        .name
                                    : "",
                                };
                                downloadOralExamResults(
                                  query,
                                  setIsDownloading
                                );
                              }}
                            >
                              {isDownloading ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ fontSize: "28px" }}
                                  ></span>
                                  <span className="sr-only">Loading...</span>
                                </>
                              ) : (
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "28px" }}
                                >
                                  cloud_download
                                </span>
                              )}
                            </IconButton>
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </div>

              <div
                className="mt-3"
                style={{
                  padding: theme.spacing(0.4),
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  overflow: "visible",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <CardContent
                  style={{
                    height: "auto",
                    minHeight: "auto",
                    overflow: "hidden",
                  }}
                >
                  {!isLoading && !!paginatedItems.length && (
                    <table className="table table-striped table-hover table-sm  fixTableHead">
                      <thead>
                        <tr style={{ backgroundColor: "#f0f2f5" }}>
                          <th scope="col">#</th>
                          <th scope="col" className="text-left">
                            Names
                          </th>

                          <th scope="col" className="text-center">
                            Phone Number
                          </th>
                          <th scope="col" className="text-left">
                            Panel
                          </th>
                          <th scope="col" className="text-center">
                            Behavior
                          </th>
                          <th scope="col" className="text-center text-truncate">
                            TechnicalKnowledge
                          </th>
                          <th scope="col" className="text-center text-truncate">
                            GeneralKnowledge
                          </th>

                          <th scope="col" className="text-center text-truncate">
                            CommunicationSkills
                          </th>
                          {!!exam.advertisement.requisition
                            .willHaveWrittenExam && (
                            <th
                              scope="col"
                              className="text-center text-truncate"
                            >
                              WrittenSkills
                            </th>
                          )}

                          <th scope="col" className="text-center">
                            TopupScore
                          </th>
                          <th scope="col" className="text-center">
                            TotalOralScore
                          </th>
                          {!!exam.advertisement.requisition
                            .willHaveWrittenExam && (
                            <th scope="col" className="text-center">
                              TotalWrittenScore
                            </th>
                          )}

                          <th scope="col" className="text-center">
                            TotalScore
                          </th>
                          <th scope="col" className="text-center">
                            Status
                          </th>
                          <th scope="col" className="text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {paginatedItems.map((oralExamMark, index) => (
                          <OralExamMarkingCard
                            oralExamMark={oralExamMark}
                            setShowTopupScore={setShowTopupScore}
                            setSelectedApplicantMarks={
                              setSelectedApplicantMarks
                            }
                            setShowApplicantOralExamAnswers={
                              setShowApplicantOralExamAnswers
                            }
                            exam={exam}
                            key={index}
                            getUserDetails={getUserDetails}
                            loading={loading}
                            publishOralTopupScore={publishOralTopupScore}
                          />
                        ))}
                      </tbody>
                    </table>
                  )}

                  <NoRecordMessage
                    isEmpty={!filteredApplicants.length && !isLoading}
                    title="No Results found"
                  />

                  <PreLoader
                    isLoading={isLoading && !applicantOralExamResults.length}
                  />
                </CardContent>
              </div>
              {showTopupScore && (
                <TopupScoreDialog
                  showTopupScore={showTopupScore}
                  setShowTopupScore={setShowTopupScore}
                  oralExamMark={selectedApplicantMarks}
                />
              )}
              {showApplicantOralExamAnswers && (
                <ApplicantOralExamAnswerDialog
                  showApplicantOralExamAnswers={showApplicantOralExamAnswers}
                  setShowApplicantOralExamAnswers={
                    setShowApplicantOralExamAnswers
                  }
                  oralExamMark={selectedApplicantMarks}
                  getUserDetails={getUserDetails}
                  loading={loading}
                  hasPermissions={hasPermissions}
                />
              )}
            </div>
          </Box>
        </DialogContent>

        {!!paginatedItems.length &&
          applicantOralExamResults.length >= itemsPerPage && (
            <DialogActions className="d-flex justify-content-center py-1">
              <div
                className="justify-content-center mt-2"
                style={{ overflow: "hidden" }}
              >
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                  activeLinkClassName="active"
                />
              </div>
            </DialogActions>
          )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading, applicantOralExamResults }) => {
  return { user, loading, applicantOralExamResults };
};

export default connect(mapStateToProps, {
  getApplicantOralExamResults,
  downloadOralExamResults,
  getUserDetails,
  publishOralTopupScore,
})(OralExamMarking);

const ITEM_HEIGHT = 60;

const OralExamMarkingCard = (props) => {
  const {
    oralExamMark,
    setShowTopupScore,
    setSelectedApplicantMarks,
    setShowApplicantOralExamAnswers,
    exam,
    getUserDetails,
    loading,
    publishOralTopupScore,
  } = props;
  const [reason, setReason] = useState("");
  const [showTopupReason, setShowTopupReason] = useState("");
  const { hasPermissions } = useRequiredAuth();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [showCurrentProfile, setShowCurrentProfile] = useState(false);
  const [confirmTopupMark, setConfirmTopupMark] = useState(false);

  const handleOpenCurrentProfile = () => {
    handleCloseMenu();
    setShowCurrentProfile(true);
  };

  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {oralExamMark.userId}
        </th>
        <td className="text-left">
          {" "}
          {oralExamMark.firstName} {oralExamMark.lastName}
        </td>

        <td className="text-center">{oralExamMark.phoneNumber}</td>
        <td className="text-left text-primary"> {oralExamMark.panelName}</td>
        <td className="text-center">
          {oralExamMark.scoreOnBehaviour || "-"}/
          {oralExamMark.markOnBehaviour || ""}
        </td>
        <td className="text-center">
          {oralExamMark.scoreOnTechnicalKnowledge || "-"}/
          {oralExamMark.markOnTechnicalKnowledge || ""}
        </td>
        <td className="text-center">
          {oralExamMark.scoreOnGeneralKnowledge || "-"}/
          {oralExamMark.markOnGeneralKnowledge || ""}
        </td>
        <td className="text-center">
          {oralExamMark.scoreOnCommunicationSkills || "-"}/
          {oralExamMark.markOnCommunicationSkills || ""}
        </td>
        {!!exam.advertisement.requisition.willHaveWrittenExam && (
          <td className="text-center">
            {oralExamMark.scoreOnWrittenSkills && (
              <>
                {oralExamMark.scoreOnWrittenSkills || "-"}/
                {oralExamMark.markOnWrittenSkills || ""}
              </>
            )}
          </td>
        )}

        <td className="text-center">
          {oralExamMark.topupScore || "-"}
          {oralExamMark.topupReason && (
            <>
              {" "}
              <br />
              <Link
                className=" my-sm-0"
                to="#"
                onClick={() => setShowTopupReason(true)}
              >
                Reason
              </Link>
            </>
          )}
        </td>

        <td className="text-center">
          {!!oralExamMark.totalOralScore && (
            <>
              {oralExamMark.totalOralScore || "-"}/
              {oralExamMark.totalMarks || ""}
            </>
          )}

          {!!oralExamMark.topupScore && (
            <>
              <strong className="text-primary" style={{ fontSize: "10px" }}>
                <br />
                {oralExamMark.oralScore || "-"}
                <sup className="ml-1 text-success">
                  +{oralExamMark.topupScore}
                </sup>
              </strong>
            </>
          )}
        </td>

        {!!exam.advertisement.requisition.willHaveWrittenExam && (
          <td className="text-center">
            {oralExamMark.totalWrittenScore && (
              <>
                {oralExamMark.totalWrittenScore || "-"}/
                {oralExamMark.totalWrittenMarks || ""}
              </>
            )}
          </td>
        )}

        <td className="text-center">
          <div
            className="text-center"
            style={{
              margin: "0 auto",
              padding: "2px",
              width: "80px",
              height: "auto",
              border: `solid 3px ${
                oralExamMark.totalScore >= 70
                  ? "#28a745"
                  : oralExamMark.totalScore >= 50
                  ? "rgb(7, 142, 206)"
                  : "#dc3545"
              }`,
              borderRadius: "50px",
              textAlign: "center",
            }}
          >
            {oralExamMark.totalScore}/100
          </div>
        </td>

        <td className="text-center">
          {oralExamMark.totalScore >= 70 ? (
            <span className="badge  badge-success">Passed Exam</span>
          ) : oralExamMark.totalScore >= 50 ? (
            <span className="badge  badge-primary ">Not Passed Exam</span>
          ) : (
            <span className="badge  badge-danger">Failed Exam</span>
          )}
        </td>

        <td className="text-center">
          <IconButton
            size="small"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                maxHeight: ITEM_HEIGHT * 4.5,
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {hasPermissions(["IS_CBM"]) && (
              <span>
                <MenuItem
                  disabled={!oralExamMark.canPublishTopupScore}
                  onClick={() => {
                    handleCloseMenu();
                    setConfirmTopupMark(true);
                  }}
                  className="text-success font-weight-light"
                >
                  <CheckCircleOutlineIcon className="mr-2 text-primary" />
                  Publish Top-up marks
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  // setSelectedApplicantMarks(oralExamMark);
                  dispatch({
                    type: "SET_APPLICANT_ORAL_EXAM_RESULT",
                    data: oralExamMark,
                  });
                  setShowApplicantOralExamAnswers(true);
                }}
                className="text-dark font-weight-light"
              >
                <QuestionAnswerIcon className="mr-2 text-primary" />
                View Answer Sheet
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowApplicationCv(true);
                }}
                className="text-primary font-weight-light"
              >
                <span className="material-icons mr-1">open_in_new</span>
                Application Profile
              </MenuItem>
              <Divider className="my-1" />
              <MenuItem
                disabled={loading}
                onClick={() => {
                  getUserDetails(oralExamMark.userId, handleOpenCurrentProfile);
                }}
                className="text-dark font-weight-light"
              >
                <BadgeIcon className="mr-2 text-primary" />
                {loading ? "Wait...." : "Currently Profile"}
              </MenuItem>
            </span>
          </Menu>
        </td>

        {showTopupReason && (
          <ConfirmationDialog
            confirmationDialog={showTopupReason}
            title="Reason"
            btnMessage="Close"
            message={
              <>
                {" "}
                <p style={{ minWidth: "300px" }}>{oralExamMark.topupReason}</p>
              </>
            }
            setConfirmationDialog={setShowTopupReason}
            noPreFormat={true}
            onYes={() => {
              setShowTopupReason(false);
            }}
          />
        )}

        {confirmTopupMark && (
          <ConfirmationDialog
            confirmationDialog={confirmTopupMark}
            disabled={!reason}
            message={
              <>
                {" "}
                <p>Are you sure you want to confirm top-up marks? </p>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  name="Reason"
                  label="Type your reason .."
                  variant="outlined"
                  placeholder="Type your reason  .."
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value || "");
                  }}
                />
              </>
            }
            setConfirmationDialog={setConfirmTopupMark}
            noPreFormat={true}
            onYes={() => {
              publishOralTopupScore(
                {
                  applicantExamSessionId: oralExamMark.id,
                  topupReason: reason,
                },
                setConfirmTopupMark
              );
            }}
          />
        )}

        {showCurrentProfile && (
          <UserResume
            showProfileDialog={showCurrentProfile}
            setShowProfileDialog={setShowCurrentProfile}
          />
        )}
        {showApplicationCv && (
          <ApplicationCv
            showApplicationCv={showApplicationCv}
            setShowApplicationCv={setShowApplicationCv}
            application={{
              id: oralExamMark.applicationId,
              idNumber: oralExamMark.idNumber,
              phoneNumber: oralExamMark.phoneNumber,
              userId: oralExamMark.userId,
              lastName: oralExamMark.lastName,
              firstName: oralExamMark.firstName,
              email: oralExamMark.email,
            }}
          />
        )}
      </tr>
    </>
  );
};
