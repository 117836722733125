import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
  Typography,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  doUploadExam,
  getQuestionTypes,
} from "../../../store/recruitment/actions";

import { showError } from "../../../app/toastify";
import ActionDialog from "../../common/components/ActionDialog";

import readXlsxFile from "read-excel-file";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ErrorMessage from "../../common/components/ErrorMessage";
import { totalSum } from "../../common/components/Utils";
import "react-quill/dist/quill.snow.css";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";

const UploadExamDialog = (props) => {
  const {
    doUploadExam,
    exam,
    showUploadExamForm,
    setShowUploadExamForm,
    questionTypes,
    getQuestionTypes,
    selectedAdvertisement,
  } = props;

  const [questionType, setQuestionType] = useState(null);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [base64Data, setBase64Data] = useState(null);
  const [open, setOpen] = React.useState(true);
  const [showUploadedDocument, setShowUploadedDocument] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  // console.log(exam);

  const [examDetailForm, setExamDetailForm] = useState({
    examId: exam ? exam.id : 0,
    file: "",
    questionTypeId: "",
    hasPDF: false,
    pdfAttachment: "",
    testForWrittenSkills: "",
  });

  const [errors, setErrors] = useState({
    fileHasError: false,
    pdfAttachmentHasError: false,
    questionTypeHasError: false,
    testForWrittenSkillsHasError: false,
    noticeHasError: false,
  });

  useEffect(() => {
    getQuestionTypes();
  }, [exam]);
  const formValidator = () => {
    const error = {
      fileHasError: false,
      pdfAttachmentHasError: false,
      questionTypeHasError: false,
      testForWrittenSkillsHasError: false,
      hasError: false,
    };

    if (!examDetailForm.file) {
      error.fileHasError = true;
      error.hasError = true;
    }
    if (
      examDetailForm.hasPDF &&
      questionType &&
      questionType.id === 1 &&
      !examDetailForm.pdfAttachment
    ) {
      error.pdfAttachmentHasError = true;
      error.hasError = true;
    }
    if (
      !!selectedAdvertisement.willHaveOralExam &&
      questionType &&
      questionType.id === 1 &&
      !examDetailForm.testForWrittenSkills
    ) {
      error.testForWrittenSkillsHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file.size;
    // let fileSize = 0;
    // if (size < 1000 * 1000 * 1000) {
    //   fileSize = size / 1000 / 1000;
    // }
    return size > 1074176 ? true : false;
  };

  const handleUploadedPDFFile = (event) => {
    let pdfAttachment = event.target.files[0];

    setErrors({ ...errors, pdfAttachmentHasError: false });
    setBase64Data(null);
    if (
      !(
        pdfAttachment.type === "application/pdf" &&
        convertToLower(pdfAttachment.name.substr(-4)) === ".pdf"
      )
    ) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Get the Base64 data
        const base64Data = e.target.result;
        setBase64Data(base64Data);
        setExamDetailForm({
          ...examDetailForm,
          pdfAttachment: base64Data,
        });
      };

      // Read the file as Data URL
      reader.readAsDataURL(pdfAttachment);
    }
  };

  const handleUploadedFile = (event) => {
    let file = event.target.files[0];
    setUploadErrors([]);
    if (
      (convertToLower(file.name.substr(-4)) === ".xls" ||
        convertToLower(file.name.substr(-5)) === ".xlsx") &&
      !isFileTooLarge(file)
    ) {
      setExamDetailForm({
        ...examDetailForm,
        file,
      });
      setErrors({ ...errors, fileHasError: false });
    } else if (isFileTooLarge(file)) {
      file = "";
      setExamDetailForm({
        ...examDetailForm,
        file,
      });
      showError("Allowed file should not exceed 3MB");
    } else {
      showError("Provide excel file");
    }
  };
  const onSave = async () => {
    if (!examDetailForm.questionTypeId) {
      showError("Question Type is required!");
      return false;
    }

    if (+examDetailForm.questionTypeId === 1) saveMultipleQuestion();
    else saveOpenQuestion();
  };
  const saveMultipleQuestion = async () => {
    if (!formValidator()) {
      if (!examDetailForm.examId) {
        showError("Exam is required!");
        return false;
      }
      let tempErrors = [];
      const questions = await readXlsxFile(examDetailForm.file).then((rows) => {
        let tempQuestions = [];

        if (!!rows.length) {
          const header = rows[0];

          if (
            header &&
            header[0] &&
            header[0].toLowerCase().includes("no") &&
            header[1] &&
            header[1].toLowerCase().includes("questions") &&
            header[2] &&
            header[2].toLowerCase().includes("a") &&
            header[3] &&
            header[3].toLowerCase().includes("b") &&
            header[4] &&
            header[4].toLowerCase().includes("c") &&
            header[5] &&
            header[5].toLowerCase().includes("d") &&
            header[6] &&
            header[6].toLowerCase().includes("marks")
          ) {
            rows.shift();

            rows.forEach((cols) => {
              let question = {
                examId: examDetailForm.examId,
                questionNo: cols[0] && cols[0] !== "" ? parseInt(cols[0]) : "",
                description:
                  cols[1] && cols[1] !== "" ? cols[1].toString().trim() : "",
                assertionA:
                  cols[2] && cols[2] !== "" ? cols[2].toString().trim() : "",
                assertionB:
                  cols[3] && cols[3] !== "" ? cols[3].toString().trim() : "",
                assertionC:
                  cols[4] && cols[4] !== "" ? cols[4].toString().trim() : "",
                assertionD:
                  cols[5] && cols[5] !== "" ? cols[5].toString().trim() : "",
                marks: cols[6] && cols[6] !== "" ? parseInt(cols[6]) : "",
                referenceQuestionNo: cols[7] && cols[7] !== "" ? cols[7] : "",
                questionTypeId: examDetailForm.questionTypeId,
              };

              let hasNoQuestionNo = false,
                hasNoAssertion = false,
                hasMoreThanOneStars = false,
                hasNoDescription = false,
                totalStars = 0,
                hasNoMarks = false;

              if (question.questionTypeId === 1) {
                if (
                  !question.assertionA ||
                  !question.assertionB ||
                  !question.assertionC ||
                  !question.assertionD
                ) {
                  hasNoAssertion = true;
                }

                if (
                  !question.assertionA.charAt(0) === "*" ||
                  !question.assertionB.charAt(0) === "*" ||
                  !question.assertionC.charAt(0) === "*" ||
                  !question.assertionD.charAt(0) === "*"
                ) {
                  hasNoAssertion = true;
                }

                if (question.assertionA.charAt(0) === "*") {
                  totalStars = totalStars + 1;
                }
                if (question.assertionB.charAt(0) === "*") {
                  totalStars = totalStars + 1;
                }
                if (question.assertionC.charAt(0) === "*") {
                  totalStars = totalStars + 1;
                }
                if (question.assertionD.charAt(0) === "*") {
                  totalStars = totalStars + 1;
                }
              }

              if (totalStars > 1) {
                hasMoreThanOneStars = true;
              }
              if (!question.questionNo) {
                hasNoQuestionNo = true;
              }
              if (!question.description) {
                hasNoDescription = true;
              }
              if (!question.marks || question.marks <= 0) {
                hasNoMarks = true;
              }
              if (
                hasNoAssertion ||
                hasNoAssertion ||
                hasNoQuestionNo ||
                hasNoDescription ||
                hasNoMarks ||
                hasMoreThanOneStars
              ) {
                tempErrors.push({
                  no: question.questionNo,
                  question: question.description,
                  hasNoAssertion,
                  hasNoQuestionNo,
                  hasNoDescription,
                  hasMoreThanOneStars,
                  hasNoMarks,
                });
              }

              tempQuestions.push(question);
            });
            setUploadErrors(tempErrors);
          } else {
            showError("Multiple Choice Excel file uploaded is mis formatted");
          }
        } else {
          showError("Multiple Choice Excel file uploaded is empty");
        }

        return tempQuestions;
      });

      if (!questions.length) {
        showError("Multiple Choice Excel file uploaded has issue!");
        return false;
      }

      if (totalSum(questions, "marks") !== 100) {
        showError("Total marks should equal to 100");
        return false;
      }

      if (!!uploadErrors.length || !!tempErrors.length) {
        showError("Please correct error(s) found!");
        return false;
      }

      if (
        examDetailForm.hasPDF &&
        +examDetailForm.questionTypeId === 1 &&
        errors.pdfAttachmentHasError
      ) {
        showError("Invalid Pdf File");
        return false;
      }
      if (
        !!selectedAdvertisement.willHaveOralExam &&
        +examDetailForm.questionTypeId === 1 &&
        !examDetailForm.testForWrittenSkills
      ) {
        showError("Test For Written Skills is required!");
        return false;
      }

      examDetailForm.questionTypeId = +examDetailForm.questionTypeId;
      examDetailForm.questions = questions;
      examDetailForm.pdfAttachment = examDetailForm.pdfAttachment
        ? examDetailForm.pdfAttachment.split(",")[1]
        : null;

      return doUploadExam(examDetailForm, onClose, setIsUploading);
    }
  };

  const saveOpenQuestion = async () => {
    if (!formValidator()) {
      if (!examDetailForm.examId) {
        showError("Exam is required!");
        return false;
      }
      let tempErrors = [];
      const questions = await readXlsxFile(examDetailForm.file).then((rows) => {
        let tempQuestions = [];
        if (!!rows.length) {
          const header = rows[0];

          if (
            header &&
            header[0] &&
            header[0].toLowerCase().includes("no") &&
            header[1] &&
            header[1].toLowerCase().includes("questions") &&
            header[2] &&
            header[2].toLowerCase().includes("marks")
          ) {
            rows.shift();

            rows.forEach((cols) => {
              let question = {
                examId: examDetailForm.examId,
                questionNo: cols[0] && cols[0] !== "" ? parseInt(cols[0]) : "",
                description:
                  cols[1] && cols[1] !== "" ? cols[1].toString() : "",
                assertionA: "",
                assertionB: "",
                assertionC: "",
                assertionD: "",
                marks: cols[2] && cols[2] !== "" ? parseInt(cols[2]) : "",
                referenceQuestionNo: cols[3] && cols[3] !== "" ? cols[3] : "",
                questionTypeId: examDetailForm.questionTypeId,
              };
              let hasNoQuestionNo = false,
                hasNoDescription = false,
                hasNoMarks = false;

              if (!question.questionNo) {
                hasNoQuestionNo = true;
              }
              if (!question.description) {
                hasNoDescription = true;
              }
              if (!question.marks || question.marks <= 0) {
                hasNoMarks = true;
              }
              if (hasNoDescription || hasNoMarks) {
                tempErrors.push({
                  no: question.questionNo,
                  hasNoQuestionNo,
                  hasNoDescription,
                  hasNoMarks,
                });
              }

              tempQuestions.push(question);
            });
            setUploadErrors(tempErrors);
          } else {
            showError("Excel file uploaded is mis formatted");
          }
        } else {
          showError("Excel file uploaded is empty");
        }

        return tempQuestions;
      });

      if (!questions.length) {
        showError("Excel file uploaded has issue!");
        return false;
      }

      if (totalSum(questions, "marks") !== 100) {
        showError("Total marks should equal to 100");
        return false;
      }

      if (!!uploadErrors.length || !!tempErrors.length) {
        showError("Please correct error(s) found!");
        return false;
      }
      if (examDetailForm.hasPDF && !examDetailForm.pdfAttachment) {
        showError("PDF Attachment is required!");
        return false;
      }
      if (
        !examDetailForm.testForWrittenSkills &&
        !!selectedAdvertisement.willHaveOralExam
      ) {
        showError("Test For Written Skills is required!");
        return false;
      }

      examDetailForm.questionTypeId = +examDetailForm.questionTypeId;
      examDetailForm.questions = questions;
      examDetailForm.pdfAttachment = examDetailForm.pdfAttachment
        ? examDetailForm.pdfAttachment.split(",")[1]
        : null;

      return doUploadExam(examDetailForm, onClose, setIsUploading);
    }
  };

  const onClose = () => {
    setExamDetailForm({
      examId: "",
      file: "",
      questionTypeId: "",
      hasPDF: false,
      pdfAttachment: "",
      testForWrittenSkills: "",
    });
    setShowUploadExamForm(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showUploadExamForm}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark">Upload Questions</span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            {showUploadedDocument && base64Data && (
              <PreviewPdfUrl
                isBase64={true}
                showDocument={showUploadedDocument}
                setShowDocument={setShowUploadedDocument}
                document={{
                  name: `View Document`,
                  url: base64Data || "",
                }}
              />
            )}
            <div className="row">
              {!!questionTypes.length && (
                <div className="col-lg-12 mt-2">
                  <Autocomplete
                    size="small"
                    id="questionType"
                    defaultValue={null}
                    value={questionType}
                    options={questionTypes}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, questionType) => {
                      const questionTypeId = questionType
                        ? questionType.id
                        : "";

                      setExamDetailForm({
                        ...examDetailForm,
                        questionTypeId,
                      });
                      setQuestionType(questionType || null);
                      setErrors({ ...errors, questionTypeHasError: false });
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, questionType) => (
                      <Box component="li" {...props} key={questionType.id}>
                        {questionType.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select question Type"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "questionTypeId", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  <ErrorMessage
                    hasError={errors.questionTypeHasError}
                    message="Question Type is required"
                  />
                </div>
              )}
              {!!selectedAdvertisement.willHaveOralExam && (
                <div className="col-lg-12 mt-3">
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    size="small"
                    name="testForWrittenSkills"
                    label={`Test For Written Skills`}
                    variant="outlined"
                    placeholder={`Test For Written Skills`}
                    value={examDetailForm.testForWrittenSkills}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const testForWrittenSkills = e.target.value;
                      setExamDetailForm({
                        ...examDetailForm,
                        testForWrittenSkills,
                      });

                      setErrors({
                        ...errors,
                        testForWrittenSkillsHasError: false,
                      });
                    }}
                  />
                  <ErrorMessage
                    hasError={errors.testForWrittenSkillsHasError}
                    message="Test For Written Skills is required"
                  />
                </div>
              )}

              <div className="col-lg-12 mt-3">
                <div className="">
                  <div className="input-group input-group-sm ">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        Does the exam have a PDF file attached?
                      </span>
                    </div>
                    <div className="form-control">
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="no"
                          name="hasPDF"
                          disabled={!questionType}
                          value={false}
                          checked={examDetailForm.hasPDF === false}
                          onChange={(event) => {
                            const hasPDF = false;
                            setExamDetailForm({
                              ...examDetailForm,
                              hasPDF,
                            });
                          }}
                        />
                        <label className="form-check-label" htmlFor="no">
                          No
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          disabled={!questionType}
                          type="radio"
                          className="form-check-input"
                          id="yes"
                          name="hasPDF"
                          value={true}
                          checked={examDetailForm.hasPDF === true}
                          onChange={(event) => {
                            const hasPDF = true;
                            setExamDetailForm({
                              ...examDetailForm,
                              hasPDF,
                            });
                          }}
                        />
                        <label className="form-check-label" htmlFor="yes">
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {examDetailForm.hasPDF && (
                <div className="col-lg-12 mt-3">
                  Exam PDF(* only pdf file)
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-paperclip"></i>
                      </span>
                    </div>

                    <input
                      disabled={!questionType}
                      type="file"
                      name="file"
                      className="form-control"
                      accept="application/pdf"
                      placeholder="Select pdf file"
                      onChange={(e) => handleUploadedPDFFile(e)}
                    />
                    {base64Data && (
                      <>
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => {
                              setShowUploadedDocument(true);
                            }}
                          >
                            View document
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  <ErrorMessage
                    hasError={errors.pdfAttachmentHasError}
                    message={
                      <>
                        <div>
                          Please check following issues:
                          <br />
                          File should be pdf format.
                        </div>
                      </>
                    }
                  />
                </div>
              )}

              <div className="col-lg-12 mt-3">
                Upload Questions(* only excel file)
                <div className="input-group mb-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-paperclip"></i>
                    </span>
                  </div>

                  <input
                    disabled={!questionType}
                    type="file"
                    name="file"
                    className="form-control"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    placeholder="Select excel file"
                    onChange={(e) => handleUploadedFile(e)}
                  />
                </div>
                <ErrorMessage
                  hasError={errors.fileHasError}
                  message={
                    <>
                      <div>
                        Please check following issues:
                        <br />
                        File should be excel format.
                      </div>
                    </>
                  }
                />
              </div>

              <>
                {!!uploadErrors.length && (
                  <div className="col-lg-12 m-2">
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        borderRadius: "2px",
                        border: "1px solid red",
                      }}
                      component="div"
                      subheader={
                        <ListSubheader
                          component="div"
                          style={{ borderBottom: "1px solid red" }}
                        >
                          Error{uploadErrors.length > 1 && "s"} found(
                          {uploadErrors.length})
                        </ListSubheader>
                      }
                    >
                      {uploadErrors.map((error, index) => (
                        <ListItemButton key={index} onClick={handleClick}>
                          <ListItemText
                            primary={
                              <span className="text-danger">
                                {error.hasNoAssertion && (
                                  <p className="pl-1">
                                    <span className="pl-1">
                                      {error.no && "Q." + error.no + ":"}
                                    </span>
                                    Check if the question is missing star(*) or
                                    empty an Assertion value.
                                  </p>
                                )}
                                {error.hasMoreThanOneStars && (
                                  <p className="pl-1">
                                    <span className="pl-1">
                                      {error.no && "Q." + error.no + ":"}
                                    </span>
                                    Check if the question has more than one
                                    star(*).
                                  </p>
                                )}

                                {error.hasNoQuestionNo && (
                                  <p className="pl-1">
                                    <span className="pl-1">
                                      {error.no && "Q." + error.no + ":"}
                                    </span>
                                    Question number is required{" "}
                                  </p>
                                )}
                                {error.hasNoDescription && (
                                  <p className="pl-1">
                                    <span className="pl-1">
                                      {error.no && "Q." + error.no + ":"}
                                    </span>
                                    The question description is required{" "}
                                  </p>
                                )}
                                {error.hasNoMarks && (
                                  <p className="pl-1">
                                    <span className="pl-1">
                                      {error.no && "Q." + error.no + ":"}
                                    </span>
                                    Check if the question mark is filled or
                                    maybe equal or less than zero.
                                  </p>
                                )}

                                {error.hasNoQuestionNo && (
                                  <p className="pl-1">
                                    <span className="pl-1">
                                      {error.no && "Q." + error.no + ":"}
                                    </span>
                                    Question number is required
                                  </p>
                                )}
                              </span>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </div>
                )}
              </>
            </div>

            {isUploading && (
              <ActionDialog showAction={isUploading} action="Uploading ..." />
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={!questionType}
          >
            {isUploading ? "Wait..." : "Submit"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, questionTypes }) => {
  return {
    loading,
    questionTypes,
  };
};
export default connect(mapStateToProps, {
  doUploadExam,
  getQuestionTypes,
})(UploadExamDialog);
