import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { showError } from "./../app/toastify";

import {
  login,
  selectModule,
  // resetPassword,
  logout,
  selectEntitySector,
  requestOTP,
} from "../store/common/actions";
import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useRequiredAuth } from "./common/guard/requiredAuth";
import CustomCountdown from "./common/components/CustomCountdown";

const Home = (props) => {
  const {
    env,
    user,
    loading,
    ippisModules,
    login,
    requestOTP,
    selectModule,
    selectEntitySector,
  } = props;
  useEffect(() => {
    document.title =
      "WELCOME TO IPPIS - THE MANAGEMENT SYSTEM FOR THE ENTIRE RWANDA PUBLIC SERVICE";
  });
  const history = useHistory();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [resending, setResending] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState(120);
  const pin1Ref = useRef(null);
  const pin2Ref = useRef(null);
  const pin3Ref = useRef(null);
  const pin4Ref = useRef(null);
  const [pin, setPin] = useState({ one: "", two: "", three: "", four: "" });

  // useEffect(
  //   () => {
  //     logout(history);
  //   },
  //
  //   []
  // );

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const handleCleanForm = () => {
    setCredentials({
      email: "",
      password: "",
      showPassword: false,
    });
    setPin({ one: "", two: "", three: "", four: "" });
    setRemainingSeconds(120);
    setFormStep(1);
    setForgotPassword(false);
    setChangePassword(false);
    setResending(false);
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      handleCleanForm();
    }
  }, [user]);

  const canVerify = () => {
    return (
      remainingSeconds > 0 &&
      pin.one &&
      pin.two &&
      pin.three &&
      pin.four &&
      !loading &&
      !resending
    );
  };

  const handleRequestOTP = () => {
    requestOTP(
      {
        email: credentials.email,
        password: credentials.password,
      },
      (status, res, data) => {
        setResending(status);
        if (data && res) {
          setRemainingSeconds(data);
          setFormStep(2);
        } else if (!data && res) {
          window.location.reload();
        }
      }
    );
  };

  const onLogin = async (e) => {
    e.preventDefault();
    if (formStep === 1 && env === "prod") {
      handleRequestOTP();
    } else if (formStep === 2 || env !== "prod") {
      login({
        email: credentials.email,
        otpCode:
          env === "prod"
            ? `${pin.one}${pin.two}${pin.three}${pin.four}`
            : undefined,
        password: env !== "prod" ? credentials.password : undefined,
        wasLoggedIn: env === "prod" ? false : true,
      });
    }
  };

  const [onWait, setOnWait] = useState(false);
  const [wasReset, setWasReset] = useState(false);

  const resetPassword = async () => {
    try {
      setOnWait(true);
      setWasReset(false);
      await axios.post("/api/auth/reset-password", {
        email: credentials.email,
      });
      setOnWait(false);
      setWasReset(true);
    } catch (error) {
      setOnWait(false);
      setWasReset(false);
      showError(error);
    }
  };

  const { hasPermissions } = useRequiredAuth();

  const filteredModules = () => {
    if (!isEmpty(user?.selectedEntitySector))
      return ippisModules.filter(
        ({ isActive, permit }) =>
          isActive &&
          (user?.selectedEntitySector?.permissions[permit] ||
            hasPermissions(["IS_ADMIN"]))
      );
    else return [];
  };

  useEffect(() => {
    if (!!user?.selectedEntitySector && filteredModules()?.length === 1)
      selectModule(filteredModules()[0], history);
  }, [user.selectedEntitySector, ippisModules]);

  const handlePINChange = (e, pinName) => {
    const value = e.target.value;
    if (isNaN(value) || value.length > 1) {
      return;
    }

    // Update the PIN
    const updatedPin = { ...pin };
    updatedPin[pinName] = value;

    // Move to the next input field if the current input is not empty
    if (value !== "") {
      if (pinName === "one") pin2Ref.current.focus();
      if (pinName === "two") pin3Ref.current.focus();
      if (pinName === "three") pin4Ref.current.focus();
    }

    setPin(updatedPin);
    e.preventDefault();
  };

  const onPasteAssignPin = (e) => {
    const value = e.clipboardData.getData("text/plain");
    if (!isNaN(value)) {
      const codesArray = value.split("");
      setPin({
        one: codesArray[0] ?? "",
        two: codesArray[1] ?? "",
        three: codesArray[2] ?? "",
        four: codesArray[3] ?? "",
      });
      pin4Ref.current.focus();
    }
    e.preventDefault();
  };

  const handleOnDeletePin = (e, pinName) => {
    if (
      (e.key === "Delete" || e.key === "Backspace") &&
      e.target.value === ""
    ) {
      if (pinName === "four") pin3Ref.current.focus();
      if (pinName === "three") pin2Ref.current.focus();
      if (pinName === "two") pin1Ref.current.focus();
    }
  };

  const handleGetHashedEmail = (email) => {
    const emailArray = email.split("@");
    const firstPart = emailArray[0];
    const atPart = emailArray[1];
    if (firstPart.length <= 3) return `${firstPart[0]}******@${atPart}`;
    else if (firstPart.length <= 5)
      return `${firstPart.substring(0, 2)}******@${atPart}`;
    else return `${firstPart.substring(0, 3)}******@${atPart}`;
  };

  return (
    <React.Fragment>
      <div className="container-fluid px-0">
        {/* <div style={{ marginTop: "0px" }}>
          <div style={{ backgroundColor: "#007bff", height: "2px" }}></div>
          <div style={{ backgroundColor: "#199e05", height: "1px" }}></div>
          <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
        </div> */}
        <div
          className="card "
          style={{
            borderRadius: "0px",
            backgroundColor: "#078ece",
          }}
        >
          <div
            className="card-body d-flex align-items-center justify-content-center"
            style={{
              borderRadius: "0px",
              height: "192px",
            }}
          >
            <p
              className="text-uppercase text-center mb-5 mt-n3"
              style={{
                fontWeight: "400",
                color: "#f1f1f1",
                fontSize: "x-large",
              }}
            >
              WELCOME TO IPPIS -{" "}
              {!!user?.selectedEntitySector
                ? user.selectedEntitySector.entityName
                : "THE MANAGEMENT SYSTEM FOR THE ENTIRE RWANDA PUBLIC SERVICE"}
            </p>
          </div>
        </div>
        {/* <div style={{ marginTop: "0px" }}>
          <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
          <div style={{ backgroundColor: "#199e05", height: "3px" }}></div>
        </div> */}

        <div className="row justify-content-center mt-n5 mx-0 px-0 text-center">
          {isEmpty(user) && (
            <>
              {!forgotPassword && !changePassword && (
                <div className="col-12 col-md-6 col-lg-5 border  px-5 py-2 pb-5 border-primary rounded bg-light">
                  {formStep === 1 ? (
                    <form onSubmit={onLogin}>
                      <h1 className="h3 my-3 font-weight-normal text-uppercase text-primary">
                        Login
                      </h1>

                      {/* <Alert severity="success" className="mb-3">
                    Your password was changed successfully
                  </Alert> */}

                      {/* <Alert
                      icon={false}
                      severity="info"
                      className="mb-3 bg-secondary text-light"
                    >
                      Please, login using your personal email and password
                    </Alert> */}

                      <FormControl
                        fullWidth
                        variant="outlined"
                        className="mb-3 mt-2 bg-white"
                      >
                        <InputLabel htmlFor="email">
                          Enter your email
                        </InputLabel>
                        <OutlinedInput
                          autoFocus
                          id="email"
                          placeholder="Enter your email"
                          name="email"
                          type="email"
                          value={credentials.email}
                          onChange={(e) => {
                            const email = e.target.value;
                            setCredentials({ ...credentials, email });
                          }}
                          disabled={loading}
                          label="Enter your email"
                        />
                      </FormControl>

                      <FormControl
                        fullWidth
                        variant="outlined"
                        className=" bg-white"
                      >
                        <InputLabel htmlFor="password">
                          Enter your password
                        </InputLabel>
                        <OutlinedInput
                          id="password"
                          placeholder="Enter your password"
                          name="password"
                          type={credentials.showPassword ? "text" : "password"}
                          value={credentials.password}
                          onChange={(e) => {
                            const password = e.target.value;
                            setCredentials({ ...credentials, password });
                          }}
                          disabled={loading}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setCredentials({
                                    ...credentials,
                                    showPassword: !credentials.showPassword,
                                  })
                                }
                                edge="end"
                              >
                                {credentials.showPassword ? (
                                  <span className="material-icons">
                                    visibility_off
                                  </span>
                                ) : (
                                  <span className="material-icons">
                                    visibility
                                  </span>
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Enter your password"
                        />
                      </FormControl>

                      <div className="text-right">
                        <Button
                          type="button"
                          onClick={() => setForgotPassword(true)}
                          className="mb-3 pr-0"
                        >
                          Reset Password
                        </Button>
                      </div>

                      {!loading && (
                        <Button
                          type="submit"
                          disabled={loading}
                          // onClick={() => onLogin()}
                          variant="contained"
                          disableElevation
                          className="btn btn-block text-light"
                          size="large"
                        >
                          Login
                        </Button>
                      )}

                      {loading && (
                        <Button
                          type="button"
                          variant="contained"
                          disableElevation
                          className="btn btn-block text-light"
                          size="large"
                        >
                          Connecting...
                        </Button>
                      )}

                      {/* <p className="mt-4 mb-3 text-muted">
                        If you don't remember your email. <br />
                        Please, contact your HR for help.
                      </p> */}
                    </form>
                  ) : (
                    <form onSubmit={onLogin}>
                      <div className="card bg-white mb-5 mt-5 border-0">
                        <div className="card-body px-5 py-0  text-center">
                          <h3 className="font-weight-bold">
                            2-Step Verification
                          </h3>
                          <p style={{ color: "#696969" }}>
                            A One-Time <strong>verification code</strong> has
                            been sent <br />
                            to your email{" "}
                            <strong>
                              {handleGetHashedEmail(credentials.email)}
                            </strong>
                          </p>

                          <div className="otp-field mb-3">
                            <input
                              ref={pin1Ref}
                              type="number"
                              placeholder="-"
                              maxLength="1"
                              value={pin.one}
                              onPaste={onPasteAssignPin}
                              onChange={(e) => handlePINChange(e, "one")}
                              onKeyUp={(e) => handleOnDeletePin(e, "one")}
                              autoFocus
                            />
                            <input
                              ref={pin2Ref}
                              type="number"
                              placeholder="-"
                              maxLength="1"
                              value={pin.two}
                              onPaste={onPasteAssignPin}
                              onChange={(e) => handlePINChange(e, "two")}
                              onKeyUp={(e) => handleOnDeletePin(e, "two")}
                            />
                            <input
                              ref={pin3Ref}
                              type="number"
                              placeholder="-"
                              maxLength="1"
                              value={pin.three}
                              onPaste={onPasteAssignPin}
                              onChange={(e) => handlePINChange(e, "three")}
                              onKeyUp={(e) => handleOnDeletePin(e, "three")}
                            />
                            <input
                              ref={pin4Ref}
                              type="number"
                              placeholder="-"
                              maxLength="1"
                              value={pin.four}
                              onPaste={onPasteAssignPin}
                              onChange={(e) => handlePINChange(e, "four")}
                              onKeyUp={(e) => handleOnDeletePin(e, "four")}
                            />
                          </div>

                          {remainingSeconds > 0 && (
                            <div className="mb-3">
                              <CustomCountdown
                                remainingSeconds={remainingSeconds}
                                setRemainingSeconds={setRemainingSeconds}
                              />
                            </div>
                          )}

                          <div
                            className="d-flex align-items-center justify-content-between mx-auto mb-2"
                            style={{ width: "180px" }}
                          >
                            <Button
                              className="mx-2"
                              variant="contained"
                              type="submit"
                              disabled={canVerify() ? false : true}
                            >
                              Verify
                            </Button>
                            <Button
                              className="mx-2"
                              variant="outlined"
                              color="primary"
                              type="button"
                              disabled={remainingSeconds > 0}
                              onClick={() => {
                                setResending(true);
                                handleRequestOTP();
                              }}
                            >
                              Resend
                            </Button>
                          </div>
                          <Button
                            variant="text"
                            type="button"
                            className="mt-4"
                            onClick={handleCleanForm}
                            // startIcon={
                            //   <span className="material-icons-round mr-1">
                            //     arrow_back
                            //   </span>
                            // }
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              )}

              {forgotPassword && (
                <div className="col-12 col-md-6 col-lg-5 border px-5 py-3 border-primary rounded bg-light">
                  <h1 className="h4 my-3 font-weight-normal text-uppercase text-primary">
                    Reset your password
                  </h1>
                  {wasReset && (
                    <Alert severity="success" className="mb-3">
                      <AlertTitle>
                        Please, check your email to continue.
                      </AlertTitle>
                      The link to reset your password was sent to your email.{" "}
                    </Alert>
                  )}

                  {/* {!wasReset && (
                    <Alert severity="success" className="mb-3">
                      The link to reset your password was sent to your email.{" "}
                      <br /> Please, check your email to continue.
                    </Alert>
                  )} */}

                  {!wasReset && (
                    <>
                      <TextField
                        fullWidth
                        autoFocus
                        name="email"
                        type="email"
                        variant="outlined"
                        label="Enter your email"
                        placeholder="Enter your email"
                        className="mt-2 mb-3 bg-white"
                        value={credentials.email}
                        onChange={(e) => {
                          const email = e.target.value;
                          setCredentials({ ...credentials, email });
                        }}
                      />

                      {!onWait && (
                        <Button
                          type="button"
                          variant="contained"
                          disableElevation
                          className="btn btn-block text-light"
                          size="large"
                          onClick={() => resetPassword()}
                        >
                          Reset password
                        </Button>
                      )}

                      {onWait && (
                        <Button
                          type="button"
                          variant="contained"
                          disableElevation
                          className="btn btn-block text-light"
                          size="large"
                          onClick={() => resetPassword()}
                        >
                          Connecting...
                        </Button>
                      )}
                    </>
                  )}

                  <Button
                    type="button"
                    onClick={() => {
                      setForgotPassword(false);
                      setOnWait(false);
                      setWasReset(false);
                    }}
                    className="pr-3 my-3"
                  >
                    <span className="material-icons">
                      keyboard_double_arrow_left
                    </span>{" "}
                    Back to Login
                  </Button>

                  {/* {!wasReset && (
                    <p className="text-muted">
                      If you don't remember your email. <br />
                      Please, contact your HR for help.
                    </p>
                  )} */}
                </div>
              )}
            </>
          )}

          {!isEmpty(user) && (
            <>
              {isEmpty(user.selectedEntitySector) && (
                <div className="col-12 col-md-11 col-lg-9 bg-light p-5 border border-primary rounded">
                  <h2
                    className=" p-2 text-center"
                    style={{
                      fontWeight: "50",
                      color: "white",
                      backgroundColor: "#078ece",
                      fontSize: "x-large",
                      border: "1px solid rgba(0, 0, 0, 0.125)",
                    }}
                  >
                    Select Institution
                  </h2>

                  <div className="row justify-content-center">
                    {user.entitySectors.map((entitySector) => {
                      return (
                        <React.Fragment key={entitySector.id}>
                          <div className="col-12 mb-2">
                            <button
                              type="button"
                              disabled={loading}
                              onClick={() => {
                                selectEntitySector(entitySector.id, history);
                              }}
                              className={`btn btn-outline-primary  text-left btn-block p-3`}
                            >
                              {entitySector.entityName} - ({" "}
                              {entitySector.sectorName})
                            </button>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              )}

              {!isEmpty(user.selectedEntitySector) && (
                <div className="col-12 col-md-11 col-lg-9 bg-light p-5 border border-primary rounded ">
                  <h2
                    className=" p-2 text-center text-uppercase"
                    style={{
                      fontWeight: "50",
                      color: "white",
                      backgroundColor: "#078ece",
                      fontSize: "x-large",
                      border: "1px solid rgba(0, 0, 0, 0.125)",
                    }}
                  >
                    Select module
                  </h2>

                  <div className="row justify-content-center">
                    {filteredModules()?.length > 1 &&
                      filteredModules().map((mod) => {
                        return (
                          <React.Fragment key={mod.shortName}>
                            {(mod.id !== "TRAINING" ||
                              (!!user.selectedEntitySector
                                .canUseTrainingModule &&
                                mod.id === "TRAINING")) &&
                              (mod.id !== "RECRUITMENT" ||
                                (!!user?.canAccessRecruitment &&
                                  mod.id === "RECRUITMENT")) && (
                                <div className="col-12 col-md-6 col-lg-4 col-xl-3 my-2">
                                  <button
                                    disabled={loading}
                                    onClick={() => selectModule(mod, history)}
                                    className={`btn btn-outline-primary  text-left btn-block p-3`}
                                  >
                                    <img
                                      src={require(`./assets/icons/${mod.icon}`)}
                                      alt=""
                                    />{" "}
                                    {mod.shortName}
                                  </button>
                                </div>
                              )}
                          </React.Fragment>
                        );
                      })}
                    {hasPermissions(["IS_ADMIN"]) && (
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3 my-2">
                        <button
                          disabled={loading}
                          onClick={() => history.push("/admin")}
                          className={`btn btn-outline-primary  text-left btn-block p-3`}
                        >
                          <span className="d-inline-flex align-items-center py-0">
                            <span className="material-icons ">security</span>
                            Admin
                          </span>
                        </button>
                      </div>
                    )}
                    {hasPermissions(["IS_SUPPORTER"]) && (
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3 my-2">
                        <button
                          disabled={loading}
                          onClick={() => history.push("/support")}
                          className={`btn btn-outline-primary  text-left btn-block p-3`}
                        >
                          <span className="d-inline-flex align-items-center py-0">
                            <span className="material-icons ">help</span>
                            Support
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  env,
  user,
  isFetchingUser,
  loading,
  ippisModules,
}) => {
  return { env, user, isFetchingUser, loading, ippisModules };
};
export default connect(mapStateToProps, {
  login,
  requestOTP,
  // resetPassword,
  logout,
  selectModule,
  selectEntitySector,
})(Home);
