const types = {
  SET_DISTRICTS: "SET_DISTRICTS",
  // SET_SECTORS: "SET_SECTORS",
  // SET_CELLS: "SET_CELLS",
  // SET_VILLAGES: "SET_VILLAGES",

  SET_USER_EDUCATIONS: "SET_USER_EDUCATIONS",
  ADD_OR_UPDATE_USER_EDUCATION: "ADD_OR_UPDATE_USER_EDUCATION",
  DELETE_USER_EDUCATION: "DELETE_USER_EDUCATION",

  SET_USER_EXPERIENCES: "SET_USER_EXPERIENCES",
  ADD_OR_UPDATE_USER_EXPERIENCE: "ADD_OR_UPDATE_USER_EXPERIENCE",
  DELETE_USER_EXPERIENCE: "DELETE_USER_EXPERIENCE",

  SET_USER_LANGUAGES: "SET_USER_LANGUAGES",
  ADD_OR_UPDATE_USER_LANGUAGE: "ADD_OR_UPDATE_USER_LANGUAGE",
  DELETE_USER_LANGUAGE: "DELETE_USER_LANGUAGE",

  SET_USER_PUBLICATIONS: "SET_USER_PUBLICATIONS",
  ADD_OR_UPDATE_USER_PUBLICATION: "ADD_OR_UPDATE_USER_PUBLICATION",
  DELETE_USER_PUBLICATION: "DELETE_USER_PUBLICATION",

  SET_USER_DISABILITIES: "SET_USER_DISABILITIES",
  ADD_OR_UPDATE_USER_DISABILITY: "ADD_OR_UPDATE_USER_DISABILITY",
  DELETE_USER_DISABILITY: "DELETE_USER_DISABILITY",

  SET_USER_CERTIFICATES: "SET_USER_CERTIFICATES",
  ADD_OR_UPDATE_USER_CERTIFICATE: "ADD_OR_UPDATE_USER_CERTIFICATE",
  DELETE_USER_CERTIFICATE: "DELETE_USER_CERTIFICATE",

  SET_USER_REFEREES: "SET_USER_REFEREES",
  ADD_OR_UPDATE_USER_REFEREE: "ADD_OR_UPDATE_USER_REFEREE",
  DELETE_USER_REFEREE: "DELETE_USER_REFEREE",

  SET_USER_PROFILE_STATUS: "SET_USER_PROFILE_STATUS",

  SET_COUNTRIES: "SET_COUNTRIES",
  SET_DEGREES: "SET_DEGREES",
  SET_EDUCATIONAL_INSTITUTIONS: "SET_EDUCATIONAL_INSTITUTIONS",
  SET_QUALIFICATIONS: "SET_QUALIFICATIONS",

  SET_CERTIFICATES: "SET_CERTIFICATES",
  SET_LANGUAGES: "SET_LANGUAGES",
  SET_DISABILITIES: "SET_DISABILITIES",
  SET_DISABILITY_LEVELS: "SET_DISABILITY_LEVELS",
  SET_USER_CV: "SET_USER_CV",
  SET_PUBLICATION_TYPES: "SET_PUBLICATION_TYPES",

  SET_REB_APPLICANT_PREFERENCES: "SET_REB_APPLICANT_PREFERENCES",

  SET_APPLICATION_CERTIFICATES: "SET_APPLICATION_CERTIFICATES",
  SET_APPLICATION_PROFILE_STATUS: "SET_APPLICATION_PROFILE_STATUS",
  SET_APPLICATION_EDUCATIONS: "SET_APPLICATION_EDUCATIONS",
  SET_APPLICATION_EXPERIENCES: "SET_APPLICATION_EXPERIENCES",
  SET_APPLICATION_DISABILITIES: "SET_APPLICATION_DISABILITIES",
  SET_APPLICATION_LANGUAGES: "SET_APPLICATION_LANGUAGES",
  SET_APPLICATION_PUBLICATIONS: "SET_APPLICATION_PUBLICATIONS",
  SET_APPLICATION_REFEREES: "SET_APPLICATION_REFEREES",
  SET_APPLICATION_CV: "SET_APPLICATION_CV",
  SET_USER_DETAILS: "SET_USER_DETAILS",
  SET_FILTER_USER_SKILLS:"SET_FILTER_USER_SKILLS"
};

export default types;
