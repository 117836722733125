import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import {
  Toolbar,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  InputAdornment,
  Select,
  Badge,
  IconButton,
  Menu,
  LinearProgress,
} from "@mui/material";

import { useRequiredAuth } from "../../common/guard/requiredAuth";
import UserResume from "../../profile/UserResume";
import {
  getUserDetails,
  getFilteredUserSkills,
} from "../../../store/profile/actions";

const SkillsProfile = (props) => {
  const { loading, filterUserSkills, getUserDetails, getFilteredUserSkills } =
    props;
  const { hasPermissions } = useRequiredAuth();
  const [showRequestForm, setShowRequestForm] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const onFind = async (e) => {
    e.preventDefault();
    getFilteredUserSkills({
      searchTerm: searchTerm,
      employeeStatus: null,
      minAge: null,
      maxAge: null,
      gender: null,
      yearsExperienceCurrentPosition: null,
      maxYearsExperienceInService: null,
      employerName: null,
      positionName: null,
      levelName: null,
      degreeName: null,
      qualificationName: null,
      nationalityName: null,
      placeOfBirth: null,
    });
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="d-flex align-items-center justify-content-between">
              <Typography
                variant="h6"
                noWrap
                component="div"
                // sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>SKILLS</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>

                <span style={{ color: "rgb(7, 142, 206)" }}>
                  <span style={{ color: "rgb(7, 142, 206)" }}>Profile</span>
                </span>
              </Typography>
            </Toolbar>
            <div className="mx-1">
              <div className="row">
                <div className="col-3">
                  <div
                    className="elevated rounded p-3"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #17a2b8",
                      minHeight: "auto",
                    }}
                  >
                    <form onSubmit={onFind}>
                      <div className="row">
                        <div className="col-12 mb-2">
                          {loading && <LinearProgress />}
                        </div>
                        <div className="col-12 mb-3">
                          <TextField
                            fullWidth
                            size="small"
                            rows={4}
                            name="searchTerm"
                            label={`Search by any keyword..`}
                            placeholder={`Search by any keyword..`}
                            variant="outlined"
                            className="font-weight-bold mb-2"
                            value={searchTerm || ""}
                            onChange={(e) => {
                              setSearchTerm(e.target.value || "");
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span
                                    style={{
                                      borderRadius: "0px",
                                    }}
                                  >
                                    <span className="material-icons mt-2">
                                      search
                                    </span>
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="col-12">
                          <button
                            disabled={loading || !searchTerm}
                            type="submit"
                            className="btn btn-primary text-uppercase ml-1 d-flex align-items-center float-right"
                          >
                            <span className="material-icons">search</span>
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-9">
                  <div
                    style={{
                      marginLeft: "1em",
                      marginRight: "1em",
                      backgroundColor: "#fff",
                      borderRadius: "0.5rem",
                      boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                      MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                      height: "55vh",
                      overflowY: "scroll",
                    }}
                  >
                    {!filterUserSkills.length && (
                      <div className="jumbotron jumbotron-fluid text-center  mt-0">
                        <div className="container py-5">
                          <p className="lead">
                            {loading ? "Searching..." : "No record found"}{" "}
                          </p>
                        </div>
                      </div>
                    )}

                    {!!filterUserSkills.length && (
                      <table className="table table-striped table-hover table-sm  fixTableHead">
                        <thead>
                          <tr style={{ backgroundColor: "#f0f2f5" }}>
                            <th scope="col">#</th>
                            <th scope="col">Names</th>
                            <th scope="col">Sex</th>
                            <th scope="col">Age</th>
                            <th scope="col">Nationality</th>
                            <th scope="col">PlaceOfBirth</th>
                            <th scope="col">Experience</th>
                            <th scope="col">YearInService</th>
                            <th scope="col">HightestDegree</th>
                            <th scope="col">Employer</th>
                            <th scope="col">Position</th>
                            <th scope="col">Level</th>
                            <th scope="col" className="text-right">
                              Status
                            </th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {filterUserSkills.map((employee, index) => (
                            <UserItem
                              employee={employee}
                              index={index}
                              key={index}
                              getUserDetails={getUserDetails}
                            />
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </AppBar>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, filterUserSkills }) => {
  return { user, loading, filterUserSkills };
};
export default connect(mapStateToProps, {
  getUserDetails,
  getFilteredUserSkills,
})(SkillsProfile);

const UserItem = ({ index, employee, loading, getUserDetails }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showEmployeeProfileDialog, setShowEmployeeProfileDialog] =
    useState(false);

  const handleOpenCurrentProfile = () => {
    handleCloseMenu();
    setShowEmployeeProfileDialog(true);
  };

  return (
    <>
      <tr key={index}>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {employee.id}
        </th>
        <td>
          {employee.lastName} {employee.firstName}
        </td>
        <td>{employee.genderId === "M" ? "Male" : "Female"}</td>
        <td>{employee.age}</td>

        <td>{employee.nationality}</td>

        <td>{employee.placeOfBirth}</td>
        <td>
          {employee.yearsExperience} year{employee.yearsExperience > 1 && "s"}
        </td>
        <td>
          {employee.yearsService} year
          {employee.yearsyearsServiceExperience > 1 && "s"}
        </td>

        <td>{employee.highestDegree}</td>
        <td>{employee.employer ? employee.employer : "N/A"}</td>
        <td>{employee.position ? employee.position : "N/A"}</td>
        <td>
          {employee.level && (
            <>
              <span className="d-flex align-items-end justify-content-end">
                <strong
                  className="text-primary"
                  style={{ fontSize: ".75rem", fontweight: "bold" }}
                >
                  {employee.level}
                </strong>
              </span>
            </>
          )}
        </td>
        <td className="text-right">
          {!!employee.employmentStatus ? (
            <strong
              className={`ml-1 badge badge-${
                employee.employmentStatus === "Employed" ? "success" : "warning"
              }`}
              style={{ fontSize: ".75rem", fontweight: "bold" }}
            >
              {employee.employmentStatus}
            </strong>
          ) : (
            "N/A"
          )}
        </td>
        <td>
          <IconButton
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  getUserDetails(employee.id, handleOpenCurrentProfile);
                }}
                className="text-primary font-weight-light"
              >
                <span className="material-icons mr-1">account_circle</span>
                {loading ? "Wait...." : "View Profile"}
              </MenuItem>
            </span>
          </Menu>
        </td>
      </tr>

      {showEmployeeProfileDialog && (
        <UserResume
          showProfileDialog={showEmployeeProfileDialog}
          setShowProfileDialog={setShowEmployeeProfileDialog}
          canEdit={false}
          allowedToEditEmail={false}
          allowedToEditEmploymentDate={false}
          enabledToViewPhoto={true}
        />
      )}
    </>
  );
};
