import React from "react";
import { useRequiredAuth } from "../common/guard/requiredAuth";
import {
  Redirect,
  Route,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import UserGuide from "../training/pages/UserGuide";
import Dashboard from "./pages/Dashboard";
import MenuBar from "./components/MenuBar";
import SkillsProfile from "./pages/SkillsProfile";

const Layout = () => {
  const { hasPermissions } = useRequiredAuth();
  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route
          path="/skills-dashboard/dashboard"
          component={Dashboard}
        />
         <Route
          path="/skills-dashboard/profile"
          component={SkillsProfile}
        />
        <Route path="/skills-dashboard/user-guide" component={UserGuide} />
        
        <Route
          path="/skills-dashboard"
          component={() => <Redirect to={"/skills-dashboard/dashboard"} />}
        />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
